import React from 'react'
import '../../assets/Css/Oneon.css'
import { useState, useEffect } from 'react'
import { ApiService } from '../../service/ApiService'
import { useNavigate } from 'react-router-dom';
// import AboutImage from '../../assets/Images/OneonOne_images/img2.png'
import Arrow from '../../assets/Images/OneonOne_images/Arrow 1.png'
// import BeneIcon from '../../assets/Images/OneonOne_images/Icons/1.png'
// import BeneIcon1 from '../../assets/Images/OneonOne_images/Icons/2.png'
// import BeneIcon2 from '../../assets/Images/OneonOne_images/Icons/3.png'
// import BeneIcon3 from '../../assets/Images/OneonOne_images/Icons/4.png'
// import BeneIcon4 from '../../assets/Images/OneonOne_images/Icons/5.png'
// import BeneIcon5 from '../../assets/Images/OneonOne_images/Icons/6.png'
// import RingIcon from '../../assets/Images/OneonOne_images/Icons/Ring_light.png'
// import ElaboIcon from '../../assets/Images/OneonOne_images/Icons/elabo.png'
// import FitHeroIcon from '../../assets/Images/OneonOne_images/Icons/fit_hero.png'
// import DumbIcon from '../../assets/Images/OneonOne_images/Icons/dum.png'
// import CalendarIcon from '../../assets/Images/OneonOne_images/Icons/Outline_Icon.png'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Accordion from 'react-bootstrap/Accordion'

import { motion } from 'framer-motion';

function Oneon() {
  const navigate = useNavigate();
  const [result, setResult] = useState([])
  const [benifit, setBenifit] = useState([])
  const [about, setAbout] = useState([])
  const [sesion, setSesion] = useState([])
  const [faq, setFaq] = useState([])

  const GetSesion = async () => {
    try {
      const response = await ApiService.OneSession();
      if (response?.data?.status == 1) {
        setSesion(response?.data?.data)
      } else {
        setSesion([])
      }
    } catch (error) {
      console.log(error, 'api erorr')
    }
  }

  const GetAbout = async () => {
    try {
      const response = await ApiService.OneAbout();
      if (response?.data?.status == 1) {
        setAbout(response?.data?.data)
      } else {
        setAbout([])
      }
    } catch (error) {
      console.log(error, 'api erorr')
    }
  }

  const Getbenifit = async () => {
    try {
      const response = await ApiService.OneBenifit();
      if (response?.data?.status == 1) {
        setBenifit(response?.data?.data)
      } else {
        setBenifit([])
      }
    } catch (error) {
      console.log(error, 'api erorr')
    }
  }

  const GetResult = async () => {
    try {
      const response = await ApiService.OneResult();
      if (response?.data?.status == 1) {
        setResult(response?.data?.data)
      } else {
        setResult([])
      }
    } catch (error) {
      console.log(error, 'api erorr')
    }
  }




  const Getfaq = async () => {
    try {
      let currentPagePath = window.location.pathname;
      if (currentPagePath.charAt(0) === '/') {
        currentPagePath = currentPagePath.substring(1);
      }
      const data = new URLSearchParams();
      data.append('page_name', currentPagePath);
      const response = await ApiService.aboutfaq(data);
      if (response?.data?.status == 1) {
        setFaq(response?.data?.data)
      } else {
        setFaq([])
      }
    } catch (error) {
      console.log(error, 'api erorr')
    }
  }



  useEffect(() => {
    window.scrollTo(0, 0);
    GetSesion();
    GetResult();
    GetAbout();
    Getfaq();
    Getbenifit()
  }, [])



  return (
    <>
      <section className='oneOnone_section'>
        <div className='one_On overflow-hidden'>
          <motion.div className='oneOn_Content'
            initial={{ opacity: 0, scale: 1.5 }}
            whileInView={{ opacity: 1, scale: 1 }}
            viewport={{ once: true }}
            transition={{
              duration: .5,
              type: "linear",
              delay: 0
            }}
          >
            {
              sesion?.map((val, i) => {
                return (
                  <>
                    <h2>{val?.main_title}</h2>
                    <h3>{val?.sub_title}</h3>
                    {/* <p>{val?.short_desc}</p> */}
                    <p>Introducing fitness transformation with a tinge of fun? Join <span>‘One on One'</span> where we make diet, health, and habits the stars of your fitness</p> <p> journey!</p>
                  </>
                )
              })
            }

          </motion.div>
        </div>
      </section>

      <section className='oneAbout_section'>
        <Container>
          <div className='oneAbout_content'>
            <motion.div
              initial={{ opacity: 0, scale: 1.5 }}
              whileInView={{ opacity: 1, scale: 1 }}
              viewport={{ once: true }}
              transition={{
                duration: .5,
                type: "linear",
                delay: 0
              }}
            >
              <h1>One-on-One</h1>
              <h2>About One-on-One</h2>
            </motion.div>
            <div className='oneAbout_column'>
              <Row className="align-items-center">
                {
                  about?.map((val, i) => {
                    return (
                      <>
                        <Col sm={6}>
                          <motion.div className='oneAbout_image'
                            initial={{ opacity: 0, x: -150 }}
                            whileInView={{ opacity: 1, x: 0 }}
                            viewport={{ once: true }}
                            transition={{
                              duration: .5,
                              type: "spring",
                              delay: 0.5
                            }}
                          >
                            <img src={`https://adm.8stepstudios.com/${val?.image}`} alt={val.imageAlt} />
                          </motion.div>
                        </Col>
                        <Col sm={6}>
                          <motion.div className='abt_content'
                            initial={{ opacity: 0, x: 150 }}
                            whileInView={{ opacity: 1, x: 0 }}
                            viewport={{ once: true }}
                            transition={{
                              duration: .5,
                              type: "spring",
                              delay: 0.5
                            }}
                          >
                            <p dangerouslySetInnerHTML={{ __html: val.long_desc }}></p>
                          </motion.div>
                        </Col>
                      </>
                    )
                  })
                }
              </Row>
              <div className='trans'>
                <p>So, why not try it out and see your fitness story transform?</p>
              </div>
            </div>

          </div>

        </Container>
      </section>

      <section className='benefits_section'>
        <Container>
          <div className='benefits_content'>
            <motion.div
              initial={{ opacity: 0, scale: 1.5 }}
              whileInView={{ opacity: 1, scale: 1 }}
              viewport={{ once: true }}
              transition={{
                duration: .5,
                type: "linear",
                delay: 0
              }}
            >
              <h1>Benefits</h1>
              <h2>Your One-On-One Benefits!</h2>
            </motion.div>
            <Row className='g-4'>
              {
                benifit?.map((val, i) => {
                  return (
                    <>
                      <Col sm={4} >
                        <motion.div className='benefit_block' key={i}
                          initial={{ opacity: 0, scale: 0.25 }}
                          whileInView={{ opacity: 1, scale: 1 }}
                          viewport={{ once: true }}
                          transition={{
                            duration: .5,
                            type: "linear",
                            delay: 0.2 * i
                          }}
                        >
                          <img src={`https://adm.8stepstudios.com/${val?.icon_img}`} alt="" />
                          <p>{val?.titile} </p>
                        </motion.div>
                      </Col>
                    </>
                  )
                })
              }
            </Row>


          </div>

        </Container>
      </section>

      <section className='result_section'>
        <Container>
          <div className='result_container'>
            <motion.div
              initial={{ opacity: 0, scale: 1.5 }}
              whileInView={{ opacity: 1, scale: 1 }}
              viewport={{ once: true }}
              transition={{
                duration: .5,
                type: "linear",
                delay: 0
              }}
            >
              <h1>Results</h1>
              <h3>How will you get the best results?</h3>
            </motion.div>
            <div className='result_step_cont'>

              {
                result?.map((val, i) => {
                  return (
                    <motion.div className='result_content'
                      initial={{ opacity: 0, y: -150 }}
                      whileInView={{ opacity: 1, y: 0 }}
                      viewport={{ once: true }}
                      transition={{
                        duration: .8,
                        type: "linear",
                        delay: 0.3 * i
                      }}
                    >
                      <div className='steps_chart'>
                        <img src={`https://adm.8stepstudios.com/${val?.icon_img}`} alt="" />

                        <h2>{val?.titile}</h2>
                      </div>
                      {result.length - 1 === i ? <></> : <div className='arrow'><img src={Arrow} alt="/" /></div>}

                    </motion.div>
                  )
                })
              }

              {/* <div className='result_content'>

                <div className='steps_chart'>
                  <img src={ElaboIcon} alt="Elaborate" />
                  <h2>Elaboarate Counselling</h2>
                </div>
                <div className='arrow'><img src={Arrow} alt="/" /></div>
              </div> */}

              {/* <div className='result_content'>

                <div className='steps_chart'>
                  <img src={FitHeroIcon} alt="hero" />
                  <h2>Fitness hero assigned</h2>
                </div>
                <div className='arrow'><img src={Arrow} alt="/" /></div>
              </div> */}

              {/* <div className='result_content'>

                <div className='steps_chart'>
                  <img src={DumbIcon} alt="dumbell" />
                  <h2>Designed personalised workouts</h2>
                </div>
                <div className='arrow'><img src={Arrow} alt="/" /></div>
              </div> */}


              {/* <div className='result_content'>

                <div className='steps_chart'>
                  <img src={CalendarIcon} alt="" />
                  <h2>Tracking Fitness Activity</h2>
                </div>

              </div> */}

            </div>
          </div>

          <div className='result_head'>
            <h3>Ready to take your fitness to the next level with our one-on-one sessions?</h3>
          </div>
          <div className='do_btn'><button className='result_btn' onClick={() => navigate("/contact")}>Enroll Now!</button></div>
        </Container>
      </section>

      <section className='result_faq'>
        <Container>
          <div className='faqs_oneon'>
            <h1>FAQ</h1>
            <h4> FAQs</h4>
            <div className='faqs_ques_ans'>
              <Accordion defaultActiveKey="0">
                {faq?.map((val, i) => (
                  <Accordion.Item eventKey={i.toString()} key={i}>
                    <Accordion.Header>{val?.faq_question}</Accordion.Header>
                    <Accordion.Body>{val?.faq_answer}</Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
          </div>
        </Container>
      </section>
    </>
  )
}

export default Oneon
