import React, {useEffect} from 'react'
import '../../assets/Css/Blogs.css';
import FeaturedImage from '../../assets/Images/blog_images/blog_main/blogfeatured.png'
import CrdImage1 from '../../assets/Images/blog_images/blog_main/cardImage1.png'
import CrdImage2 from '../../assets/Images/blog_images/blog_main/cardImage2.png'
import CrdImage3 from '../../assets/Images/blog_images/blog_main/cardImage3.png'
import CrdImage4 from '../../assets/Images/blog_images/blog_main/cardImage4.png'
import CrdImage5 from '../../assets/Images/blog_images/blog_main/cardImage5.png'
import CrdImage6 from '../../assets/Images/blog_images/blog_main/cardImage6.png'

import Instagram from '../../Components/Instagram';

import Container from 'react-bootstrap/Container'
import { useNavigate } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap'
import Card from 'react-bootstrap/Card'

function BlogSection() {
    const navigate = useNavigate();

    const buttonClick = () => {
        navigate('/blogs');
    }
    const cardClick = () => {
        navigate('/blogSec')
    }

    useEffect(() => {
        window.scrollTo(0, 0);
      }, [])

    return (
        <>
            <div className='blogSec_backgroud'>
                <div className='blogSec_content'>
                    <h2>Fitness <span>Encyclopedia</span></h2>
                    <p>Dive into the world of fitness insights, tips, and inspiration! Join our blog community for a daily dose of wellness wisdom. Let's make every scroll a step closer to your fitness goals.</p>
                </div>
            </div>

            <section className='blogGuide_section'>
                <div className='blog_featured'>
                    <Row className="align-items-center">
                        <Col sm={6}>
                            < div className='blog_col1_content'>
                                <img src={FeaturedImage} alt="" />
                            </div>
                        </Col>
                        <Col sm={6}>
                            <div className='blog_col2_content'>
                                <p><span>FEATURED</span></p>
                                <p>The beginner's guide
                                    to weight lifting</p>
                                <div className='col_2_para'><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p></div>
                                <button className='mainButton mt-4' onClick={()=>navigate('/blogSec')}>Read More!</button>
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>

            <section className='blogPost_section'>
                <div className='blogPost_content'>
                    <h2>Recent <span> Posts</span> </h2>
                    <Row>
                        <Col sm={4}>
                            <Card className='blogPosts' onClick={cardClick}>
                                <Card.Body>
                                    <Card.Img src={CrdImage1} />
                                    <div className='blogCrd_content'>
                                        <p>5 Tips to build  daily
                                            movement routine</p>
                                        <button className='mainButton small-btn mt-4' onClick={buttonClick}> Read More!</button>
                                    </div>
                                </Card.Body>
                            </Card>

                        </Col>
                        <Col sm={4}>
                            <Card className='blogPosts' onClick={cardClick}>
                                <Card.Body>
                                    <Card.Img src={CrdImage2} />
                                    <div className='blogCrd_content'>
                                        <p>The beginner's guide
                                            to weight lifting</p>
                                        <button className='mainButton small-btn mt-4' onClick={buttonClick}> Read More!</button>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={4}>
                            <Card className='blogPosts' onClick={cardClick}>
                                <Card.Body>
                                    <Card.Img src={CrdImage3} />
                                    <div className='blogCrd_content'>
                                        <p>Why breathwork
                                            matters in your workout</p>
                                        <button className='mainButton small-btn mt-4' onClick={buttonClick}> Read More!</button>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={4}>
                            <Card className='blogPosts' onClick={cardClick}>
                                <Card.Body>
                                    <Card.Img src={CrdImage4} />
                                    <div className='blogCrd_content'>
                                        <p>6 basic weight lifting
                                            training for starters</p>
                                        <button className='mainButton small-btn mt-4' onClick={buttonClick}> Read More!</button>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={4}>
                            <Card className='blogPosts' onClick={cardClick}>
                                <Card.Body>
                                    <Card.Img src={CrdImage5} />
                                    <div className='blogCrd_content'>
                                        <p>The beginner’s guide
                                            to weight lifting</p>
                                        <button className='mainButton small-btn mt-4' onClick={buttonClick}> Read More!</button>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={4}>
                            <Card className='blogPosts' onClick={cardClick}>
                                <Card.Body>
                                    <Card.Img src={CrdImage6} />
                                    <div className='blogCrd_content'>
                                        <p>Using ropes to increase
                                            your core strenght </p>
                                        <button className='mainButton small-btn mt-4' onClick={buttonClick}> Read More!</button>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </section>

            <section className='blogSec_socials'>
                <Container>
                    <div className='social_header'>
                        <p>Looking for daily doses of motivation? Our blog is the remedy! Join our community and let's rewrite your fitness story, one blog post at a time!</p>
                        <button className='mainButton' onClick={()=>navigate('/contact')}> Join Now!</button>
                    </div>
                </Container>
            </section>

            {/* social media instagram */}
            <Instagram />
            {/*  */}
        </>
    )
}

export default BlogSection
