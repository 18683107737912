import React, { useState, useEffect } from 'react'
import Container from 'react-bootstrap/Container';
import '../../assets/Css/Hiit.css';
import CheckIcon from '../../assets/Images/CoursePage_images/Check.png';
import CoursesCrds from '../../assets/Images/CoursePage_images/cousresCrd.png';
import clock from '../../assets/Images/CoursePage_images/clock.png'
import intenstiy from '../../assets/Images/CoursePage_images/Group 186.png'
import bars from '../../assets/Images/CoursePage_images/Frame 116.png';
import reviewImg from '../../assets/Images/CoursePage_images/review.png';
import rateStar from '../../assets/Images/CoursePage_images/Group 18.png'
import { useLocation, useNavigate, } from 'react-router-dom';
import { ApiService } from '../../service/ApiService';


const TipsList = ({ htmlString }) => {
    // Function to parse the HTML string and extract text content
    const parseHtmlToList = (htmlString) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, 'text/html');
        const listItems = doc.querySelectorAll('ul > li');
        const tipsArray = Array.from(listItems).map(li => li.textContent);
        return tipsArray;
    };

    // Parse the HTML string to get the tips array
    const tipsArray = parseHtmlToList(htmlString);

    return (
        <div className='checklist_section service-space'>
            {tipsArray.map((tip, index) => (
                <div className='check_content' key={index}>
                         <img src={CheckIcon} alt="Check icon" />
                    <p>{tip}</p>
                </div>
            ))}
        </div>
    );
};

function Zumba() {

    const location = useLocation();
    const val = location?.pathname;
    const str = val?.replace('/', '')
    const navigate = useNavigate();
    const [subMenulist, setSubMenuList] = useState([])
    const [testimonialData, setTestimonialData] = useState([])
    const GetSubMenuData = async () => {
        try {
            const formData = new URLSearchParams();
            formData.append("slug", str);
            const response = await ApiService.Course(formData);
            console.log(response?.data?.status)
            if (response?.data?.status == 1) {
                setSubMenuList(response?.data?.data)
            } else {
                setSubMenuList([])
            }
        } catch (error) {
            console.log(error, 'api erorr')
        }
    }
 const htmlString=subMenulist?.map((item,i)=>item?.tips)
    const GetTestimonialData = async () => {
        try {
            const formData = new URLSearchParams();
            formData.append("page_name", str);
            const response = await ApiService.GetTestimonial(formData);
            if (response?.data?.status == 1) {
                setTestimonialData(response?.data?.data)
            } else {
                setTestimonialData([])
            }
        } catch (error) {
            console.log(error, 'api erorr')
        }
    }






    useEffect(() => {
        window.scrollTo(0, 0);
        GetSubMenuData()
        GetTestimonialData()
    }, [str])
    return (
        <>
            {
                subMenulist?.map((data) => {
                    return (
                        <>
                            <main>

                                <div className='courses_cards'>
                                    <div className='courses_crads_one'>
                                        <img className="course_img" src={`https://adm.8stepstudios.com/${data?.fitness_course_details[0]?.image}`} />
                                        <div className='courses_crds_content'>
                                            <h2>Course Details</h2>
                                            <div className='courses_activity'>
                                                <div className='courses_activity_one'>
                                                    <img src={clock} />
                                                    <span>Duration : </span>
                                                    <h3>{data?.fitness_course_details[0]?.duration} </h3>
                                                </div>
                                                <div className='courses_activity_one'>
                                                    <img src={intenstiy} style={{ marginRight: 3 }} />
                                                    <span>Intenstiy : </span>
                                                    <h3>{data?.fitness_course_details[0]?.intensity}</h3>
                                                </div>
                                                <div className='courses_activity_one'>
                                                    <img src={bars} style={{ marginRight: 3 }} />
                                                    <span>Fitness level : </span>
                                                    <h3>{data?.fitness_course_details[0]?.fitness_level}</h3>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className='courses_instructor'>
                                                <p>Instructor:</p>
                                                <div dangerouslySetInnerHTML={{ __html: data?.fitness_course_details[0]?.instructor_name }}></div>
                                            </div>
                                            <button onClick={(() => navigate('/contact'))}> BOOK NOW</button>
                                        </div>
                                    </div>
                                </div>

                                <section className='course_section'>
                                    <div className='course_bg'>
                                        <div className='category_content service-space'>
                                            <h2>{data?.title}</h2>
                                            <div dangerouslySetInnerHTML={{ __html: data?.long_desc }}></div>
                                        </div>
                                    </div>
                                </section>

                                <section className='courseAbout_section'>
                                    <Container fluid>
                                        <div className='courseAbout_content service-space'>
                                            <h2>About the <span>Course</span></h2>
                                            <p dangerouslySetInnerHTML={{ __html: data?.classes_desc }}></p>
                                        </div>
                                            <TipsList htmlString={htmlString} />
                                            
                                    </Container>
                                </section>
                                <section className='courseSchedule_section'>
                                    <Container fluid>
                                        <div className='courseSchedule_content service-space'>
                                            <h2>Course <span>Schedule</span></h2>
                                            <div className='courseSchedule_header'>
                                                <h3>{data?.week_name}</h3>
                                                <ul>
                                                    <li>{data?.mrng_start_time?.slice(0, -3)} AM-{data?.mrng_end_time?.slice(0, -3)} PM</li>
                                                    <li>{data?.eve_start_time.slice(0, -3)} PM- {data?.eve_end_time?.slice(0, -3)} PM</li>
                                                </ul>


                                            </div>
                                        </div>
                                    </Container>
                                </section>


                                <section className='customerReview_section'>
                                    <Container fluid>
                                        <div className='customerReview_header service-space'>
                                            <h2>What our <span>customer</span> says</h2>
                                        </div>
                                        <div className='courses_review_crad service-space'>
                                            {
                                                testimonialData?.map((data, i) => {

                                                    return (
                                                        <div className='course_cards_review' key={i}>
                                                            <div className='courses_review_img'>
                                                                <img src={`https://adm.8stepstudios.com/${data?.profile_img}`} alt="Profile Image" />




                                                                <div className='customer_cont'>
                                                                    <p>{data?.customer_name}</p>
                                                                    <img src={rateStar} />
                                                                </div>
                                                            </div>

                                                            <p dangerouslySetInnerHTML={{ __html: data?.testimonial_desc }}></p>

                                                        </div>
                                                    )
                                                })
                                            }

                                            {/* <div className='course_cards_review'>
                                                <div className='courses_review_img'>
                                                    <img src={reviewImg} />
                                                    <div className='customer_cont'>
                                                        <p>customer 2</p>
                                                        <img src={rateStar} />
                                                    </div>
                                                </div>
                                                <p>Zumba with Samar Sir is an absolute blast! From the moment you step into his class, you're greeted with infectious energy and a vibrant atmosphere that makes every session feel like a fun session.  With Samar Sir, Zumba isn't just about getting fit—it's about celebrating life and feeling alive with every dance move. </p>
                                            </div>
                                            <div className='course_cards_review'>
                                                <div className='courses_review_img'>
                                                    <img src={reviewImg} />
                                                    <div className='customer_cont'>
                                                        <p>customer 3</p>
                                                        <img src={rateStar} />
                                                    </div>
                                                </div>
                                                <p>Samar Sir’s Zumba classes are a fusion of joy and fitness that keeps me hooked! His charisma and enthusiasm are truly remarkable, creating an environment where you can't help but smile and let loose. Thanks to him, what was once a dreaded workout routine has transformed into something I genuinely look forward to. </p>
                                            </div> */}
                                        </div>
                                    </Container>
                                </section>


                            </main >
                            <div className='category_last_section'>
                                <p>{data?.content}</p>
                                <button onClick={(() => navigate('/contact'))}>Join Now!</button>
                            </div>
                        </>
                    )
                })
            }

        </>
    )
}

export default Zumba