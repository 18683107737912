import React from 'react'
import '../../assets/Css/Program.css';
import Container from 'react-bootstrap/Container'
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col";
import crdPhoto from '../../assets/Images/program_images/cardone.png';
import cardphotoone from '../../assets/Images/program_images/cardtwo.png';
import cardthree from '../../assets/Images/program_images/cardthree.png';
import Check from '../../assets/Images/tick.png';
import figImg from '../../assets/Images/program_images/figureimg.png';
import { FaStar } from 'react-icons/fa6';
import { useState, useEffect } from 'react'
import { ApiService } from '../../service/ApiService'
import { useNavigate } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion'

function Program() {
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedItemCat, setSelectedItemCasst] = useState(null);
  const navigate = useNavigate()
  const [program, setProgram] = useState([])
  const [faq, setFaq] = useState([])
  const Getprogram = async () => {
    try {
      const response = await ApiService.Program();
      if (response?.data?.status == 1) {
        setProgram(response?.data?.data)
      } else {
        setProgram([])
      }
    } catch (error) {
      console.log(error, 'api erorr')
    }
  }
  const Getfaq = async () => {
    try {
      let currentPagePath = window.location.pathname;
      if (currentPagePath.charAt(0) === '/') {
        currentPagePath = currentPagePath.substring(1);
      }
      const data = new URLSearchParams();
      data.append('page_name', currentPagePath);
      const response = await ApiService.aboutfaq(data);
      if (response?.data?.status == 1) {
        setFaq(response?.data?.data)
      } else {
        setFaq([])
      }
    } catch (error) {
      console.log(error, 'api erorr')
    }
  }

  const handleSelect = (eventKey, event) => {
    setSelectedItem(eventKey);
  };

  const handleCategory = (eventKey, event) => {
    setSelectedItemCasst(eventKey);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    Getprogram();
    Getfaq()
  }, [])
  return (
    <>
      <div className='background_program'>
        <div className='bg_content'>
          <h2>From sprints to serenity</h2>
          <h3>Find Your Stride With Our Diverse Programs</h3>
          <p>Get started on a transformative journey at 8 Step Studios, where our diverse courses are a combination of overall wellness, carefully designed to integrate nutrition, diet, and holistic health. Picture your goals as pieces of a puzzle – our courses bring them together at a reasonable price, delivering not just fitness but a lifestyle. Because why settle for a piece when you can have the whole puzzle? Join us on the road to holistic wellness – where every step is a stride towards a happier, healthier you!</p>
          <button className='mainButton mt-5' onClick={()=>navigate('/contact')}> Know More!</button>
        </div>
      </div>

      <section className='program_explore'>
        <div className='explore_heading'>
          <h1>Programs</h1>
          <h2>Explore Our <span>Programs</span></h2>
        </div>
        <div className='explore_prog_cards'>
          {program?.map((program, index) => (
            <div key={index} className='explore_cards_program'>
              {/* <img src={program.image || crdPhoto} alt="Program Image" /> */}
              <img src={`https://adm.8stepstudios.com/${program.image}`} alt="" />

              <div className='card_prog_content'>
                <h2>{program.prog_name}</h2>
                <div className='memb'>
                  {/* Assuming prog_desc is in HTML format */}
                  <div dangerouslySetInnerHTML={{ __html: program.prog_desc }}></div>
                </div>
                <button className='mainButton small-btn mt-4'onClick={() => {
  const beforeAmpersand = program.prog_name.split('&')[0];
  navigate(`/${beforeAmpersand}`);
}}
>Read More</button>
              </div>
            </div>
          ))}


        </div>

      </section>

      <section className='prog_transformation'>
        <Container>
          <div className='program_slider'>
            <Row className="align-items-center">
              <Col sm={6}>
                <div className='program_content'>
                  <h2>8 Steps Studio <span>Special</span></h2>
                  <p>Experience a dynamic blend of workouts throughout the week. From exhilarating HIIT sessions to lively Zumba classes, tranquil yoga and strengthening Pilates sessions, empowering weight training, and energetic Bollywood fitness routines, there's something for everyone to enjoy.</p>
                </div>
                <div className='program_instructors'>
                  <p>Instructors :</p>
                  <div className='program_trainee_rate'>
                    <div className='progrm_rate_cds'>
                      <img src="" alt="" />

                      <div className='program_rate_spn'>
                        <span>Lokesh</span>
                      </div>
                      <div className='star_rate'>
                        <FaStar />
                        <FaStar />
                        <FaStar />
                        <FaStar />
                        <FaStar />
                      </div>
                    </div>
                  </div>
                </div>
                {/* <button className='pprograRead_button'>Know More!</button> */}
              </Col>
              <Col sm={6}>
                <div className='program_image'>
                  <img src={figImg} alt="" />
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>

      <section className='prog_transformation reverse_prog'>
        <Container>
          <div className='program_slider'>
            <Row className="align-items-center">
              <Col sm={6}>
                <div className='program_image'>
                  <img src={figImg} alt="" />
                </div>
              </Col>
              <Col sm={6}>
                <div className='program_content'>
                  <h2>90 Days <span>Transformation</span></h2>
                  <p>Join our 90-day program to reach your fitness goals with six days of workouts, balanced nutrition, and personalized guidance from our mentors. Let us support you in building consistency and achieving your best self.</p>
                </div>
                <div className='program_instructors'>
                  <p>Instructors :</p>
                  <div className='program_trainee_rate'>
                    <div className='progrm_rate_cds'>
                      <img src="" alt="" />

                      <div className='program_rate_spn'>
                        <span>Lokesh</span>
                      </div>
                      <div className='star_rate'>
                        <FaStar />
                        <FaStar />
                        <FaStar />
                        <FaStar />
                        <FaStar />
                      </div>
                    </div>
                    <div className='progrm_rate_cds'>
                      <img src="" alt="" />

                      <div className='program_rate_spn'>
                        <span>Jaduveer</span>
                      </div>
                      <div className='star_rate'>
                        <FaStar />
                        <FaStar />
                        <FaStar />
                        <FaStar />
                        <FaStar />
                      </div>
                    </div>
                  </div>
                </div>
                {/* <button className='pprograRead_button'>Know More!</button> */}
              </Col>
            </Row>
          </div>
        </Container>
      </section>



      {/* <section className='prog_transformation'>
        <Container>
          <div className='program_slider'>
            <Row>
                      <Col>
                        <div className='program_content'>
                          <h2></h2>
                          <span></span>
                        </div>
                        <div className='program_instructors'>
                          <p>Instructors :</p>
                          <div className='program_trainee_rate'>
                            <div className='progrm_rate_cds'>
                              <img  src="" alt="" />

                              <div className='program_rate_spn'>
                                <span></span>
                                <p></p>
                              </div>
                              <div className='star_rate'>
                                <BiStar />
                                <BiStar />
                                <BiStar />
                                <BiStar />
                                <BiStar />
                              </div>
                            </div>
                          </div>
                        </div>
                        <button className='pprograRead_button'>Know More!</button>
                      </Col>
                      <Col>
                        <div className='program_image'>
                          <img src={figImg} alt="" />
                        </div>
                      </Col>
            </Row>
          </div>
        </Container>
      </section> */}

      <section className='prog_coursee'>
        <div className='program_course_heading'>
          <h1>Courses</h1>
          <h2>Fitnesstic Course Explorer</h2>
        </div>
        {/* <div className='program_dropdown'>
          <div className='category_dropdown'>
            <span>Category: </span>
            <Dropdown onSelect={handleCategory}>
              <Dropdown.Toggle variant="white" id="dropdown-basic">
                {selectedItemCat || 'Select an item'}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item eventKey="zumba">Zumba</Dropdown.Item>
                <Dropdown.Item eventKey="Trainning">Trainning</Dropdown.Item>
                <Dropdown.Item eventKey="Yoga">Yoga</Dropdown.Item>
                <Dropdown.Item eventKey="Something else">Something else</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className='category_dropdown'>
            <span>Seniority: </span>
            <Dropdown onSelect={handleSelect}>
              <Dropdown.Toggle variant="white" id="dropdown-basic">
                {selectedItem || 'Select an item'}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item eventKey="zumba">Zumba</Dropdown.Item>
                <Dropdown.Item eventKey="Trainning">Trainning</Dropdown.Item>
                <Dropdown.Item eventKey="Yoga">Yoga</Dropdown.Item>
                <Dropdown.Item eventKey="Something else">Something else</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div> */}
      </section>

      <section className='program_explore_fitnesstic'>
        <div className='explore_prog_cards_fitness'>
          <div className='explore_cards_program_fitness'>
            <img src={crdPhoto} />
            <div className='card_prog_content'>
              <h2>Fat Loss Program</h2>
              {/* <span>2 Months Duration</span> */}
              <div className='memb'>
                <div className='memb_pl'>
                  <img src={Check} alt="" />
                  <p>High-Intensity Interval Training</p>
                </div>
                <div className='memb_pl'>
                  <img src={Check} alt="" />
                  <p>Calorie Deficit Diet</p>
                </div>
                <div className='memb_pl'>
                  <img src={Check} alt="" />
                  <p>Tracking Progress</p>
                </div>
              </div>
              <div className='program_rate_btn'>
                {/* <button className='program_btn_read'>Read More</button> */}
                <div className='star_rate_fitness'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                  </svg>
                  <span>(89)</span>
                </div>
              </div>
            </div>
          </div>
          <div className='explore_cards_program_fitness'>
            <img src={cardphotoone} />
            <div className='card_prog_content'>
              <h2>Weight Managment Program</h2>
              {/* <span>3 Months Duration</span> */}
              <div className='memb'>
                <div className='memb_pl'>
                  <img src={Check} alt="" />
                  <p>Home Cooked Meal Plan</p>
                </div>
                <div className='memb_pl'>
                  <img src={Check} alt="" />
                  <p>Regular Workout</p>
                </div>
                <div className='memb_pl'>
                  <img src={Check} alt="" />
                  <p>Continous Motivation</p>
                </div>
              </div>
              <div className='program_rate_btn'>
                {/* <button className='program_btn_read'>Read More</button> */}
                <div className='star_rate_fitness'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                  </svg>
                  <span>(445)</span>
                </div>
              </div>
            </div>
          </div>
          <div className='explore_cards_program_fitness'>
            <img src={cardthree} />
            <div className='card_prog_content'>
              <h2>Strength Training </h2>
              {/* <span>3 Months Duration</span> */}
              <div className='memb'>
                <div className='memb_pl'>
                  <img src={Check} alt="" />
                  <p>Weight Training</p>
                </div>
                <div className='memb_pl'>
                  <img src={Check} alt="" />
                  <p>Protein-Rich Diet</p>
                </div>
                <div className='memb_pl'>
                  <img src={Check} alt="" />
                  <p>Performance Tracking</p>
                </div>
              </div>
              <div className='program_rate_btn'>
                {/* <button className='program_btn_read'>Read More</button> */}
                <div className='star_rate_fitness'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                  </svg>
                  <span>(455)</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='explore_prog_cards_fitness'>
          <div className='explore_cards_program_fitness'>
            <img src={crdPhoto} />
            <div className='card_prog_content'>
              <h2>Inch Loss Program</h2>
              {/* <span>2 Months Duration</span> */}
              <div className='memb'>
                <div className='memb_pl'>
                  <img src={Check} alt="" />
                  <p>Low Carb Diet</p>
                </div>
                <div className='memb_pl'>
                  <img src={Check} alt="" />
                  <p>Detox and Hydration</p>
                </div>
                <div className='memb_pl'>
                  <img src={Check} alt="" />
                  <p>Zumba and HIIT</p>
                </div>
              </div>
              <div className='program_rate_btn'>
                {/* <button className='program_btn_read'>Read More</button> */}
                <div className='star_rate_fitness'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                  </svg>
                  <span>(89)</span>
                </div>
              </div>
            </div>
          </div>
          <div className='explore_cards_program_fitness'>
            <img src={cardphotoone} />
            <div className='card_prog_content'>
              <h2>Stamina Boosting And Hard Core Trainning</h2>
              {/* <span>3 Months Duration</span> */}
              <div className='memb'>
                <div className='memb_pl'>
                  <img src={Check} alt="" />
                  <p>Clean Diet</p>
                </div>
                <div className='memb_pl'>
                  <img src={Check} alt="" />
                  <p>Strength and Cardio</p>
                </div>
                <div className='memb_pl'>
                  <img src={Check} alt="" />
                  <p>Motivation Towards Consistency</p>
                </div>
              </div>
              <div className='program_rate_btn'>
                {/* <button className='program_btn_read'>Read More</button> */}
                <div className='star_rate_fitness'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                  </svg>
                  <span>(445)</span>
                </div>
              </div>
            </div>
          </div>
          <div className='explore_cards_program_fitness'>
            <img src={cardthree} />
            <div className='card_prog_content'>
              <h2>Harmonal Balance & Wellness Program </h2>
              {/* <span>3 Months Duration</span> */}
              <div className='memb'>
                <div className='memb_pl'>
                  <img src={Check} alt="" />
                  <p>Stress Mangement</p>
                </div>
                <div className='memb_pl'>
                  <img src={Check} alt="" />
                  <p>Mindful Eating</p>
                </div>
                <div className='memb_pl'>
                  <img src={Check} alt="" />
                  <p>Yoga & Meditation</p>
                </div>
              </div>
              <div className='program_rate_btn'>
                {/* <button className='program_btn_read'>Read More</button> */}
                <div className='star_rate_fitness'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                  </svg>
                  <span>(455)</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      {/* <section className='result_faq'>
        <Container>
          <div className='faqs'>
            <h1>FAQ</h1>
            <h4> FAQs</h4>
            <div className='faqs_ques_ans'>
              <div className='faqs_one'>
                <div className='faqs_heading'>
                  <div className='faqs_dots'></div>
                  <div className='faqs_question'>
                    <p>How they can train online with same effectiveness?</p>
                  </div>
                </div>
                <div className='faqs_answer'>
                  <p>We designed all our courses in the effective way that gives you same result with better result as we assist you 24*7 with help of app timetable and whatsapp chat support all day long  </p>
                </div>
              </div>
              <div className='faqs_one'>
                <div className='faqs_heading'>
                  <div className='faqs_dots'></div>
                  <div className='faqs_question'>
                    <p>How they can train online with same effectiveness?</p>
                  </div>
                </div>
                <div className='faqs_answer'>
                  <p>We designed all our courses in the effective way that gives you same result with better result as we assist you 24*7 with help of app timetable and whatsapp chat support all day long  </p>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section> */}


      <section className='result_faq'>
        <Container>
          <div className='faqs_oneon'>
            <h1>FAQ</h1>
            <h4> FAQs</h4>
            <div className='faqs_ques_ans'>
              <Accordion defaultActiveKey="0">
                {faq?.map((val, i) => (
                  <Accordion.Item eventKey={i.toString()} key={i}>
                    <Accordion.Header>{val?.faq_question}</Accordion.Header>
                    <Accordion.Body>{val?.faq_answer}</Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
          </div>
        </Container>
      </section>
    </>
  )
}

export default Program
