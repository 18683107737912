import React from 'react'
import '../../assets/Css/Branch.css'
import CImage from '../../assets/Images/BranchPage_Images/img1.png'
import Phone from '../../assets/Images/Contact_Images/Icons/tel.png'
import MapIcon from '../../assets/Images/Contact_Images/Icons/map.png'
import BaImage from '../../assets/Images/BranchPage_Images/img2.png'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useState, useEffect } from 'react'
import { ApiService } from '../../service/ApiService';
import { Link } from 'react-router-dom';


function Branch() {
  const [visit, setVisit] = useState([])
  const GetVisitUs = async () => {
    try {
      const response = await ApiService.visitUS();
      if (response?.data?.status == 1) {
        setVisit(response?.data?.data)
      } else {
        setVisit([])
      }
    } catch (error) {
      console.log(error, 'api erorr')
    }
  }


  useEffect(() => {
    window.scrollTo(0, 0);
    GetVisitUs();
  }, [])
  return (
    <>
      <div className='branch_background'>
        <div className='branch_content'>
          <h2>Branching Out For You:</h2>
          <h3>Eight Step Studios Across the Map!</h3>
          <p>Step into the 'Branches' section at 8 Step Studios, because fitness should be as exciting as discovering a new favourite dish! Our branches aren't just places to work out; they're your fitness playgrounds. With a lineup of expert trainers and a variety of workouts that'll have you high-fiving your health goals, From the latest equipment to an energetic atmosphere, it's a fitness party waiting to happen. So, explore the fitness branches below—your soon-to-be favourite fitness destination is just a click away!</p>
        </div>
      </div>

      <section className='branchAdd_section'>
        <Container>
          <div className='branchAdd_content'>
            <h1>Branches</h1>
            <h3>Our Branches</h3>
            {
              visit?.map((item, i) => {
                const heading = item?.visit_name.split(" ")
                const lastElement = heading[heading.length - 1]
                const extarHeading = heading.slice(0, -1)?.join(" ")
                // console.log(extarHeading)
                return (
                  <div className='branch_another'>
                    <Row>
                      <Col sm={6}>
                        <div className='brnch_content2'>
                          <h2>{extarHeading} <span>{lastElement}</span></h2>
                          <p>{item?.visit_short_desc}</p>
                        </div>
                        <div className='brnch_contact_sec'>
                          <img src={Phone} alt="" />
                          <p><a href={`tel:${item?.visit_contact_no}`}>{item?.visit_contact_no}</a></p>
                        </div>
                        <div className='brnch_map_sec'>
                          <img src={MapIcon} alt="" />
                          <p>{item?.visit_address}</p>
                        </div>
                      </Col>
                      <Col sm={6}>
                        <div className='brnch_content2Img'>
                          <img src={`https://adm.8stepstudios.com/${item?.image}`} alt="" />
                        </div>
                      </Col>
                    </Row>
                  </div>
                )
              })
            }

          </div>
        </Container>

      </section>

      <section className='step_googleMap'>
        <Container fluid>
          <iframe title="Google Map Location" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3557.1235197697406!2d75.7877597753411!3d26.931298376636043!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396db36809a30b77%3A0x1e490b22bf98d184!2s8%20Step%20Studios%20Bani%20Park!5e0!3m2!1sen!2sin!4v1703048522663!5m2!1sen!2sin" width="100%" height="567px" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </Container>
      </section>

    </>
  )
}

export default Branch
