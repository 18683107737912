import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react'
import { ApiService } from '../../service/ApiService'
import bImage from '../../assets/Images/about_images/Rectangle 4230.png'
import CImage from '../../assets/Images/about_images/Rectangle 4231.png'

import Phone from '../../assets/Images/Contact_Images/Icons/tel.png'
import MapIcon from '../../assets/Images/Contact_Images/Icons/map.png'
import BaImage from '../../assets/Images/BranchPage_Images/img2.png'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import '../../assets/Css/About.css';
import Container from 'react-bootstrap/Container'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import mapLoc from '../../assets/Images/about_images/mapLoc.png';
import Accordion from 'react-bootstrap/Accordion'
import { Swiper, SwiperSlide } from 'swiper/react';

import { motion } from 'framer-motion';

import { Autoplay, Navigation } from 'swiper/modules';

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} custom-prev-arrow`}
      style={{ ...style }}
      onClick={onClick}
    >
      <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M29.4739 13.5801C28.7587 12.8214 27.5829 12.8048 26.8478 13.5429L17.5621 22.8673C17.209 23.2218 17.0068 23.7067 17.0002 24.2151C16.9935 24.7235 17.1829 25.2138 17.5266 25.5781L26.8123 35.4204C27.5278 36.1789 28.7036 36.195 29.4384 35.4566C30.1733 34.7181 30.189 33.5047 29.4734 32.7462L21.4838 24.2776L29.4379 16.2904C30.1731 15.5522 30.1892 14.3388 29.4739 13.5801Z" fill="white" />
        <circle cx="24" cy="24" r="23.5" stroke="white" stroke-opacity="0.57" />
      </svg>
    </div>
  );
};

// Custom Next Arrow component
const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} custom-next-arrow`}
      style={{ ...style }}
      onClick={onClick}
    >
      <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5665 13.5801C18.3368 12.8214 19.603 12.8048 20.3947 13.5429L30.3947 22.8673C30.775 23.2218 30.9926 23.7067 30.9998 24.2151C31.007 24.7235 30.8031 25.2138 30.4329 25.5781L20.4329 35.4204C19.6624 36.1789 18.3962 36.195 17.6048 35.4566C16.8134 34.7181 16.7965 33.5047 17.5671 32.7462L26.1713 24.2776L17.6053 16.2904C16.8136 15.5522 16.7963 14.3388 17.5665 13.5801Z" fill="#FFE459" />
        <circle cx="24" cy="24" r="23.5" stroke="white" stroke-opacity="0.59" />
      </svg>

    </div>
  );
};

function Branch() {
  const navigate = useNavigate();
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "100px",
    slidesToShow: 2,
    speed: 500
  };
  const [faq, setFaq] = useState([])
  const [team, setTeam] = useState([])
  const [value, setValue] = useState([])
  const [about, setAbout] = useState([])
  const [visit, setVisit] = useState([])


  const Getfaq = async () => {
    try {
      let currentPagePath = window.location.pathname;
      if (currentPagePath.charAt(0) === '/') {
        currentPagePath = currentPagePath.substring(1);
      }
      const data = new URLSearchParams();
      data.append('page_name', currentPagePath);
      const response = await ApiService.aboutfaq(data);
      if (response?.data?.status == 1) {
        setFaq(response?.data?.data)
      } else {
        setFaq([])
      }
    } catch (error) {
      console.log(error, 'api erorr')
    }
  }

  const Getteam = async () => {
    try {
      const response = await ApiService.Teammember();
      if (response?.data?.status == 1) {
        setTeam(response?.data?.data)
      } else {
        setTeam([])
      }
    } catch (error) {
      console.log(error, 'api erorr')
    }
  }


  const GetValue = async () => {
    try {
      const response = await ApiService.Value();
      if (response?.data?.status == 1) {
        setValue(response?.data?.data)
      } else {
        setValue([])
      }
    } catch (error) {
      console.log(error, 'api erorr')
    }
  }

  const GetAbout = async () => {
    try {
      const response = await ApiService.AboutUS();
      if (response?.data?.status == 1) {
        setAbout(response?.data?.data)
      } else {
        setAbout([])
      }
    } catch (error) {
      console.log(error, 'api erorr')
    }
  }


  const GetVisitUs = async () => {
    try {
      const response = await ApiService.visitUS();
      if (response?.data?.status == 1) {
        setVisit(response?.data?.data)
      } else {
        setVisit([])
      }
    } catch (error) {
      console.log(error, 'api erorr')
    }
  }


  useEffect(() => {
    window.scrollTo(0, 0);
    Getteam();
    Getfaq();
    GetValue();
    GetAbout();
    GetVisitUs();
  }, [])

  return (
    <>
      <section className='aboutUs_section'>

        <div className='about_us overflow-hidden'>
          <motion.div
            initial={{ opacity: 0, scale: 1.7 }}
            whileInView={{ opacity: 1, scale: 1 }}
            viewport={{ once: true }}
            transition={{
              duration: .5,
              type: "linear",
              delay: 0
            }}
          >
            <div className='aboutus_Content'>
              <h2>Beyond Barbells: <p>Explore the Essence of Eight-Step Studios</p> </h2>
              <h4>At 8 Step Studios , we redefine fitness as a dynamic lifestyle, but rest assured, there's no judgement here, even if your favourite yoga pose is 'Shavasana.' We offer a plethora of services. Whether you're into serene yoga with premium mats, lively Zumba sessions, or core-strengthening Pilates, our expert trainers are here to guide you through it all. And yes, our luxurious amenities will make you forget you're working out!</h4>
            </div>
          </motion.div>
        </div>
        <div className='next_paragraph_abut'>
          <p>Want to know more about how we found the fun in fitness amidst life's twists and turns? Dive into Our Story section!</p>
        </div>
      </section>

      <section className='about_sweat'>
        <div className='about_heading_swaet'>
          <h1>Story</h1>
          <h2>Sweat Stories</h2>
        </div>
        <div className='about_steps_sweat'>
          <div class="about_vertical"></div>

          {
            about?.map((val, i) => {
              return (
                <motion.div className='about_steps_heading'
                  initial={{ opacity: 0, y: 125 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{
                    duration: 1.6,
                    type: "spring",
                    delay: 0.2 * i
                  }}
                >
                  <div className='about_main_paragraph'>
                    <h4>{val?.main_heading} </h4>
                    <span>{val?.sub_heading?.replace('<p>', '').replace('</p>', '')}</span>
                  </div>
                </motion.div>
              )
            })
          }
        </div>

        <div className='about_steps_innovation'>
          <p>Let's lift the curtain on the'Values Section'—where our values are as precious as the last donut on a cheat day, only a lot healthier!</p>
        </div>
      </section>

      <section className='about_Values overflow-hidden'>
        <div className='about_value_container'>
          <h1>Values</h1>
          <h2>Our Values</h2>
        </div>
        <div className='about_value_cards overflow-hidden'>
          {
            value?.map((val, i) => {

              return (
                <motion.div className='aboutUs_ourValue'
                  initial={{ opacity: 0, scale: 0.2 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  viewport={{ once: true }}
                  transition={{
                    duration: .6,
                    type: "linear",
                    delay: 0.2 * i
                  }}
                >
                  <h1>{val?.value_title}</h1>
                  <span>{val?.value_desc?.replace('<p>', '').replace('</p>', '')}</span>
                </motion.div>
              )
            })
          }

          {/* <div className='aboutUs_ourValue'>
            <h1>Laughter Lifts</h1>
            <span>Your joy is our ultimate aim, and we believe that laughter is the best workout companion. We are committed to making your fitness journey a delightful and customer-centric experience.</span>
          </div>
          <div className='aboutUs_ourValue'>
            <h1>Laughter Lifts</h1>
            <span>Your joy is our ultimate aim, and we believe that laughter is the best workout companion. We are committed to making your fitness journey a delightful and customer-centric experience.</span>
          </div>
          <div className='aboutUs_ourValue'>
            <h1>Laughter Lifts</h1>
            <span>Your joy is our ultimate aim, and we believe that laughter is the best workout companion. We are committed to making your fitness journey a delightful and customer-centric experience.</span>
          </div>
          <div className='aboutUs_ourValue'>
            <h1>Laughter Lifts</h1>
            <span>Your joy is our ultimate aim, and we believe that laughter is the best workout companion. We are committed to making your fitness journey a delightful and customer-centric experience.</span>
          </div>
          <div className='aboutUs_ourValue'>
            <h1>Laughter Lifts</h1>
            <span>Your joy is our ultimate aim, and we believe that laughter is the best workout companion. We are committed to making your fitness journey a delightful and customer-centric experience.</span>
          </div> */}
        </div>
      </section>

      <section className='slider_section'>


        <Container fluid>
          <motion.div className='about_team_heading'
            initial={{ opacity: 0, scale: 1.5 }}
            whileInView={{ opacity: 1, scale: 1 }}
            viewport={{ once: true }}
            transition={{
              duration: .6,
              type: "linear",
              delay: 0
            }}
          >
            <h1>Team</h1>
            <h2>Our Team</h2>
          </motion.div>
          <div className='about_team_span'>
            <span>Introducing the incredible individuals who make fitness feel like a party – our team! because sweating with laughter is our specialty!</span>
          </div>
          <motion.div
            initial={{ opacity: 0, y: -125 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{
              duration: 1,
              type: "spring",
              delay: 0.4
            }}
          >
            <Swiper
              slidesPerView={'auto'}
              spaceBetween={0}

              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              // loop={true}
              centeredSlides={true}
              navigation={true}
              modules={[Autoplay, Navigation]}
              className="fit-hero-slicer "
            >
              {
                team?.map((val, i) => {
                  return (
                    <SwiperSlide key={i}>

                      <div className='card_body' key={i}>
                        <img className='card_image'
                          src={val?.image ? `https://adm.8stepstudios.com/${val?.image}` : 'https://developers.elementor.com/docs/assets/img/elementor-placeholder-image.png'}
                          // src='https://images.unsplash.com/photo-1715034136201-00dc287f7868?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
                          alt="cardimage" />
                        <div className='card_para'>
                          <p>{i + 1}/{team.length}</p>
                          <h6>{val?.name}</h6>
                          <h5>{val?.designation}</h5>
                          <p dangerouslySetInnerHTML={{ __html: val?.team_desc }}></p>

                        </div>
                      </div>
                    </SwiperSlide>
                  )
                })
              }

            </Swiper>

          </motion.div>
        </Container>
      </section>

      <section className='map_location overflow-hidden'>
        <div className="heading_text">
          <h1>Who We <span>Are?</span></h1>
        </div>
        <motion.div className='about_team_heading  overflow-hidden'
          initial={{ opacity: 0, scale: 1.25 }}
          whileInView={{ opacity: 1, scale: 1 }}
          viewport={{ once: true }}
          transition={{
            duration: .6,
            type: "linear",
            delay: 0
          }}
        >
          <img src={mapLoc} alt="Map" />
        </motion.div>
        <div className='map_loc_para'>
          <p>Become a part of our fitness tribe! Dive into the adventure. Enrol today and let the journey begin</p>
        </div>
        <div className='button_location'>
          <button className='mainButton' onClick={() => navigate('/contact')}>Join Now!</button>
        </div>
      </section>

      <section className='location_about'>
        <div className='location_heading'>
          <h1>Locations</h1>
          <h2>Visit us Today</h2>
        </div>
        <div className='location_container'>
          <Row>
            <Col sm={12}>
              <div className='brnch_image'>
                <img src={bImage} alt="" className='w-100' />
              </div>
            </Col>
            <Col sm={12}>
              <div className='brnch_content'>
              <h2>Banipark <span> Branch</span></h2>
                <p>Prep yourself for the energetic world of 8 Step Studios, Bani Park branch, where every workout is fun and every burnt calorie is a fitness-tic gain to your health! </p>
              </div>
              <div className='brnch_contact'>
                <img src={Phone} alt="" />
                <p><a href='tel:+91-8003040506'>+91-8003040506</a></p>
              </div>
              <div className='brnch_map'>
                <img src={MapIcon} alt="" />
                <p>Ras house, D-230, Tulsi Marg, Sindhi Colony, Bani Park, Jaipur, Rajasthan 302016</p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <div className='brnch_image'>
                <img src={CImage} alt="" className='w-100' />
              </div>
            </Col>
            <Col sm={12}>
              <div className='brnch_content'>
                <h2>C-Scheme <span> Branch</span></h2>
                <p>Welcome to the C-Scheme branch at 8 Step Studios, where fitness meets holistic wellness! We are here to design the perfect fitness formula, customised just for you.</p>
              </div>
              <div className='brnch_contact'>
                <img src={Phone} alt="" />
                <p><a href='tel:+91-8003040506'>+91-8003040506</a></p>
              </div>
              <div className='brnch_map'>
                <img src={MapIcon} alt="" />
                <p>G-2, Parivahan Marg, behind Hotel Rajmahal Palace, Shivaji Nagar, C-Scheme, Jaipur, Rajasthan 302001</p>
              </div>
            </Col>
          </Row>
        </div>


        <div className='button_location'>
          <button className='mainButton' onClick={() => navigate('/contact')}>Know More!</button>
        </div>
      </section>

      <section className='result_faq'>
        <Container>
          <div className='faqs_oneon'>
            <h1>FAQ</h1>
            <h4> FAQs</h4>
            <div className='faqs_ques_ans'>
              <Accordion defaultActiveKey="0">
                {faq?.map((val, i) => (
                  <Accordion.Item eventKey={i.toString()} key={i}>
                    <Accordion.Header>{val?.faq_question}</Accordion.Header>
                    <Accordion.Body>{val?.faq_answer}</Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
          </div>
        </Container>
      </section>

    </>
  )
}

export default Branch
