import React, { useState } from 'react';
import '../../assets/Css/Navbar.css';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import NavDropdown from 'react-bootstrap/NavDropdown';
import logoImg from "../../assets/Images/logo.png";
import { useEffect } from 'react'
import { ApiService } from '../../service/ApiService'
import Zumba from '../../Pages/zumba/Zumba';
import { Navbar as BsNavbar, Container } from 'react-bootstrap';
import { Nav } from 'react-bootstrap';

const Navbar = () => {
    const navigation = useNavigate()
    // const [isServicesDropdownOpen, setServicesDropdownOpen] = useState(false);
    // const [isAboutDropdownOpen, setAboutDropdownOpen] = useState(false);

    // const handleServicesDropdown = () => {
    //     setServicesDropdownOpen(!isServicesDropdownOpen);
    // };

    // const handleAboutDropdown = () => {
    //     setAboutDropdownOpen(!isAboutDropdownOpen);
    // };
    const [menulist, setMenuList] = useState([])
    const [saveUrl, setSaveUrl] = useState()


    // console.log(str, 'str');
    const GetMenuList = async () => {
        try {
            const response = await ApiService.Menulist();
            if (response?.data?.status == "1") {
                setMenuList(response?.data?.data)
            } else {
                setMenuList([])
            }
        } catch (error) {
            console.log(error, 'api erorr')
        }
    }



    // const handleSubMenu = (url) => {
    //     // console.log(str, 'streeee')
    //     setSaveUrl(str)
    //     // GetSubMenuData()

    // }
    useEffect(() => {
        GetMenuList();
        // GetSubMenuData();

    }, [])

    const [show, setShow] = useState({});

    const showDropdown = (i) => {
        setShow(prevState => ({ ...prevState, [i]: true }));
    };

    const hideDropdown = (i) => {
        setShow(prevState => ({ ...prevState, [i]: false }));
    };


    const [showPhoneMenu, setShowPhoneMenu] = useState(false);
    const togglePhoneMenu = () => {
        setShowPhoneMenu(!showPhoneMenu);
    };


    const loaction = useLocation()
    return (
        <>
            <BsNavbar expand="lg" className="header px-lg-4">
                <Container fluid className='bg-white'>
                    <BsNavbar.Brand href="#">
                        <Link to="/">
                            <div className="logo">
                                <img src={logoImg} alt="Logo" />
                            </div>
                        </Link>
                    </BsNavbar.Brand>
                    <BsNavbar.Toggle aria-controls="navbarScroll" />
                    <BsNavbar.Collapse id="navbarScroll">
                        <Nav className="ms-auto my-4 my-lg-0 gap-3 align-items-center">
                            {
                                menulist?.map((item, i) => {
                                    // console.log(item?.menu_name,'item');
                                    return (
                                        item?.mstr_sub_menu_navigations[0] == undefined ?
                                            <Nav.Link className={`pb-1 flex-grow-1 ${
                                                // console.log(loaction.pathname, item?.url)
                                                loaction.pathname == '/' + item?.url
                                                    ? 'active'
                                                    : ""
                                                }
                                            `} href={item?.url}>{item?.menu_name}</Nav.Link>
                                            :
                                            <NavDropdown className={`drop-item flex-grow-1 ${item?.mstr_sub_menu_navigations?.find((el) => "/"+el?.url === loaction.pathname)?.url ? "active" : ""}`} title={item?.menu_name} id="basic-nav-dropdown"
                                                show={show[i]}
                                                onMouseEnter={() => showDropdown(i)}
                                                onMouseLeave={() => hideDropdown(i)}
                                            >
                                                {
                                                    item?.mstr_sub_menu_navigations?.map((subItem,i) => {
                                                    
                                                        return (
                                                            <NavDropdown.Item href={subItem?.url} className={"/"+subItem?.url === loaction.pathname ? 'active' : "" }>
                                                                {subItem?.sub_menu_name}
                                                            </NavDropdown.Item>
                                                        )
                                                    })
                                                }
                                            </NavDropdown>
                                    )
                                })
                            }
                            <button className='hero_btn mt-0 py-1 nav-button' onClick={() => navigation("/contact")}>
                                Contact Us
                            </button>
                        </Nav>
                    </BsNavbar.Collapse>
                </Container>
            </BsNavbar>
            <Zumba />
        </>
    );
};

export default Navbar;
