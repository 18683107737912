import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react'
import { ApiService } from '../../service/ApiService'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Icon1 from '../../assets/Images/Icons/dumbbell.png'
import Icon2 from '../../assets/Images/Icons/people.png'
import Icon3 from '../../assets/Images/Icons/coach.png'
import Icon4 from '../../assets/Images/Icons/smile.png'
import cardImage7 from '../../assets/Images/yoga.png'
import cardImage8 from '../../assets/Images/zumba.png'
import cardImage9 from '../../assets/Images/gym.png'
import cardImage10 from '../../assets/Images/strength.png'
import cardImage11 from '../../assets/Images/hiit.png'
import cardImage12 from '../../assets/Images/pilates.png'
import cardImage13 from '../../assets/Images/client.png'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from '../../assets/Images/img2.png'
import Image1 from '../../assets/Images/img3.png'
import Image2 from '../../assets/Images/img5.png'
import Image3 from '../../assets/Images/training.png'
import Image4 from '../../assets/Images/img6.png'
import Image5 from '../../assets/Images/img8.png'
import Image6 from '../../assets/Images/slide1.png'
import Image7 from '../../assets/Images/slide2.png'
import Image8 from '../../assets/Images/slide3.png'
import Image9 from '../../assets/Images/fitness.png'

import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/Css/Home.css';
import '../../../src/index.css';

import Instagram from '../../Components/Instagram';

import { motion } from "framer-motion";

import { Swiper, SwiperSlide } from 'swiper/react';

import { Autoplay, Navigation } from 'swiper/modules';

function Home() {

  const navigate = useNavigate();
  const buttonClick = () => {
    navigate('/membership');
  }
  const handleButtonClick = () => {
    navigate('/about-us')
  }
  const cardClick = (route) => {
    navigate(route)
  }

  const settings = {
    className: "center",
    centerMode: false,
    infinite: false,
    // centerPadding: "100px",
    slidesToShow: 2,
    speed: 500
  };
  const slide = {
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };
  const slideFitness = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    className: "sm-master",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };
  const fit = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  }

  // Custom Previous Arrow component
  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} custom-prev-arrow`}
        style={{ ...style }}
        onClick={onClick}
      >
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M29.4739 13.5801C28.7587 12.8214 27.5829 12.8048 26.8478 13.5429L17.5621 22.8673C17.209 23.2218 17.0068 23.7067 17.0002 24.2151C16.9935 24.7235 17.1829 25.2138 17.5266 25.5781L26.8123 35.4204C27.5278 36.1789 28.7036 36.195 29.4384 35.4566C30.1733 34.7181 30.189 33.5047 29.4734 32.7462L21.4838 24.2776L29.4379 16.2904C30.1731 15.5522 30.1892 14.3388 29.4739 13.5801Z" fill="white" />
          <circle cx="24" cy="24" r="23.5" stroke="white" stroke-opacity="0.57" />
        </svg>
      </div>
    );
  };

  // Custom Next Arrow component
  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} custom-next-arrow`}
        style={{ ...style }}
        onClick={onClick}
      >
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5665 13.5801C18.3368 12.8214 19.603 12.8048 20.3947 13.5429L30.3947 22.8673C30.775 23.2218 30.9926 23.7067 30.9998 24.2151C31.007 24.7235 30.8031 25.2138 30.4329 25.5781L20.4329 35.4204C19.6624 36.1789 18.3962 36.195 17.6048 35.4566C16.8134 34.7181 16.7965 33.5047 17.5671 32.7462L26.1713 24.2776L17.6053 16.2904C16.8136 15.5522 16.7963 14.3388 17.5665 13.5801Z" fill="#FFE459" />
          <circle cx="24" cy="24" r="23.5" stroke="white" stroke-opacity="0.59" />
        </svg>

      </div>
    );
  };



  const [team, setTeam] = useState([])
  const [data, setData] = useState([])
  const [fitnessdata, setFitnessData] = useState([])
  const [blogdata, setBlogData] = useState([])

  const Getfitness = async () => {
    try {
      const response = await ApiService.Fitness();
      if (response?.data?.status == 1) {
        setFitnessData(response?.data?.data)
      } else {
        setFitnessData([])
      }
    } catch (error) {
      console.log(error, 'api erorr')
    }
  }

  const Getteam = async () => {
    try {
      const response = await ApiService.Teammember();
      if (response?.data?.status == 1) {
        setTeam(response?.data?.data)
      } else {
        setTeam([])
      }
    } catch (error) {
      console.log(error, 'api erorr')
    }
  }

  const Gettestimonial = async () => {
    try {
      const response = await ApiService.Testimonial();
      if (response?.data?.status == 1) {
        setData(response?.data?.data)
      } else {
        setData([])
      }
    } catch (error) {
      console.log(error, 'api erorr')
    }
  }

  const GetBlog = async () => {
    try {
      const response = await ApiService.Testimonial();
      if (response?.data?.status == 1) {
        setBlogData(response?.data?.data)
      } else {
        setBlogData([])
      }
    } catch (error) {
      console.log(error, 'api erorr')
    }
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    Gettestimonial();
    Getteam();
    Getfitness();
    GetBlog();
  }, [])

  // ------- Animation
  const ref = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // When the section comes into view, set isVisible to true
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      },
      { threshold: 0.5 } // Adjust the threshold based on when you want the animation to start
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    // Cleanup function
    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return (
    <div className='overflow-hidden'>
      <section className='heroSection position-relative'>
        <div className="heading">
          <motion.div
            initial={{ opacity: 0, x: -150 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{
              duration: 1,
              type: "spring",
              delay: 0
            }}
          >
            <h4 className='customHeading'>Jump start your holistic</h4>
            <h1 className='Heading' >Fitness-tic <br /> Experience</h1>
            <p style={{ fontFamily: 'poppins' }}>Where fitness is not a Goal , It's a Lifestyle</p>

          </motion.div>
          <motion.div
            initial={{ opacity: 0, }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{
              duration: 1.2,
              type: "spring",
              delay: 0.4
            }}
          >
            <p className='heroSection_para' style={{ fontFamily: 'Nutino-sans' }}>At 8 Step Studios, we'll make you sweat, laugh, and wonder <br /> why you ever skipped leg day-all in the name of holistic  <br />fitness! We're not just shaping bodies; we're sculpting holistic <br /> health one laugh at a time.</p>
            <h4 className='mg'>Are you ready for the fitness party?</h4>
            <button className='mainButton mt-3' onClick={buttonClick}> Take your first step! </button>
          </motion.div>

        </div>

      </section>

      <section className="ourVision_wrapper" ref={ref}>
        <Container >
          <div className='headingContent'>
            <motion.div
              initial={{ opacity: 0, y: -100 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{
                duration: 1,
                type: "spring",
                delay: 0.0
              }}
            >
              <h4><span className='h4_span'>Ready to dive into the heart of our mission?</span> </h4>
              <h3>Let's start with the grand vision that fuels our fitness adventure!</h3>
            </motion.div>
          </div>
          <Row>
            <Col sm={6}>
              <motion.div className="our_visionContent"
                initial={{ opacity: 0, x: -150 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                transition={{
                  duration: 1,
                  type: "spring",
                  delay: 0.2
                }}
              >
                {/* <p>Ready to dive into the heart of our mission?</p>
                <p> Let's start with the grand vision that fuels our fitness adventure.</p> */}
                <h1 className='custom' > Our <span>Vision</span></h1>
                <span className='ourVision_span'>To create a world where fitness isn't a chore, but a happy show you never want to miss. We dream of a future where holistic wellness means having a six-pack of laughter, a strong core of joy, and a heart that beats with good health.</span>
                <p className='ourVision_paragraph'>Feeling the excitement? Let’s make our vision your fitness success story!</p>
                <button className='mainButton' onClick={buttonClick}> Yes! I'm Ready</button>
              </motion.div>
            </Col>
            <Col sm={6}>
              <motion.div
                initial={{ x: 250, opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                viewport={{ once: true }}
                transition={{ ease: "easeOut", duration: .8, type: "spring", delay: 0.2 }}
              >
                <div className='img'><img src={Image} alt="Something here!" /></div>
              </motion.div>
            </Col>
          </Row>
        </Container>
      </section>
      {/*  */}

      {/* about us */}
      <section className='knowAbout'>
        <motion.div className='knowAbout_heading2'
          initial={{ opacity: 0, y: -150 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{
            duration: 1,
            type: "spring",
            delay: 0
          }}
        >
          <p>We're your gym-azing happy place. Curious to know more <br />  <span> Read On !</span></p>
        </motion.div>
        <div className='heading3'>
          <motion.div
            initial={{ opacity: 0, scale: 0.7 }}
            whileInView={{ opacity: 1, scale: 1 }}
            viewport={{ once: true }}
            transition={{
              duration: 1,
              type: "spring",
              delay: .6
            }}
          >
            <h1>8step Studios</h1>
            <h3> Welcome to  <span> 8step Studios </span> </h3>
          </motion.div>
          <p>Where we've turned the old saying on its head – 'The Only Place Where Pain Leads to Gain.' Our fitness studio boasts a vibrant range of services and classes, encompassing everything from yoga and Pilates to the irresistible beats of Zumba. Join us, and let's laugh our way to better health!</p>
          <button className='mainButton' onClick={handleButtonClick}> About Us </button>
          <span className='about_span'>Looking for the reasons why we're the right fit for you? Discover Now! </span>
        </div>
      </section>
      {/*  */}

      {/* WHY CHOOSE US */}
      <section className='whyChooseus_wrapper'>
        <div className='whyChoose_mainCont'>
          <motion.div className='image_content'
            initial={{ opacity: 0, x: -150 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{
              duration: 1,
              type: "spring",
              delay: 0
            }}
          >
            <img className="" src={Image1} alt="Someting here!" />
          </motion.div>
          <div className='whyChoose_cards'>
            <motion.div className='head2'
              initial={{ opacity: 0, x: 150 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{
                duration: 1,
                type: "spring",
                delay: 0
              }}
            >
              <h2>WHY CHOOSE<span> US? </span></h2>
            </motion.div>
            <div className='why_facilies_cont'>
              <motion.div className='facilities_content'
                initial={{ opacity: 0, x: -50 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                transition={{
                  duration: 1.5,
                  type: "spring",
                  delay: 0.4
                }}
              >
                <div className='icon Icon_1'>
                  <img src={Icon1} alt="icon1" />
                </div>
                <h6>Facilities</h6>
                <p>Our state-of-the-art facilities aren't just for workouts; because we believe fitness should be fun and fancy.</p>
              </motion.div>
              <motion.div className='facilities_content'
                initial={{ opacity: 0, x: -50 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                transition={{
                  duration: 1.5,
                  type: "spring",
                  delay: 0.6
                }}
              >
                <div className='icon Icon_2'><img src={Icon2} alt="icon2" /></div>
                <h6>Diverse Membership Plans</h6>
                <p>With monthly, yearly, and customized options, we've got plans as diverse as our yoga poses!</p>
              </motion.div>
              <motion.div className='facilities_content'
                initial={{ opacity: 0, x: -50 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                transition={{
                  duration: 1.5,
                  type: "spring",
                  delay: 0.8
                }}
              >
                <div className=' icon Icon_3'><img src={Icon3} alt="icon3" /></div>
                <h6>Train-credible Team</h6>
                <p>Our certified trainers are like personal cheerleaders, here to turn your potential into vitality.</p>
              </motion.div>
              <motion.div className='facilities_content'
                initial={{ opacity: 0, x: -50 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                transition={{
                  duration: 1.5,
                  type: "spring",
                  delay: 1
                }}
              >
                <div className=' icon Icon_4'><img src={Icon4} alt="icon4" /></div>
                <h6>Achieve-Humor-ny</h6>
                <p>It's not just about reaching your full potential; it's about exercising your way to a fit, fantastic future!</p>
              </motion.div>
            </div>
          </div>
        </div>
        <motion.div className='heading4'
          initial={{ opacity: 0, y: 150 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{
            duration: 1,
            type: "spring",
            delay: 0
          }}
        >
          <h3>"So, Don't Just Netflix and Chill – Join Us And Experience The Ultimate Fitness Thrill!"</h3>
          <button className='mainButton mt-4' onClick={buttonClick}>I'm ready for the thrill</button>
          {/* <div className='head'><h5>Our services are the perfect recipe for turning your fitness frowns into flexing smiles..</h5></div> */}
        </motion.div>
      </section>
      {/*  */}

      {/* Our Services */}
      <section className='serviceSection'>
        <Container >
          <div className='bg_image'>
            <motion.div className='bgImage_content'
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              transition={{
                duration: 1.6,
                type: "spring",
                delay: 0.2,
                ease: "ease-out"
              }}
            >
              <h1>Our <span> Services </span></h1>
              <p>At 8 Step Studios, we are dedicated to helping you achieve your health and fitness goals. We offer a wide range of fitness services and classes, including yoga, Pilates, Zumba, and much more.</p>
            </motion.div>
            <Row>
              <Col sm={4}>
                <motion.div className='demo' onClick={() => cardClick('/zumba')}
                  initial={{ opacity: 0, y: 150 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{
                    duration: 1.4,
                    type: "spring",
                    delay: 0.2
                  }}
                >
                  <img src={Image2} alt="Something here" />
                  <p>Zumba</p>
                </motion.div>
              </Col>
              <Col sm={4}>
                <motion.div className='demo' onClick={() => cardClick('/gym')}
                  initial={{ opacity: 0, y: 150 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{
                    duration: 1.4,
                    type: "spring",
                    delay: 0.4
                  }}
                >
                  <img src={Image3} alt="Something here" />
                  <p>Gym</p>
                </motion.div>
              </Col>
              <Col sm={4}>
                <motion.div className='demo' onClick={() => cardClick('/swimming')}
                  initial={{ opacity: 0, y: 150 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{
                    duration: 1.4,
                    type: "spring",
                    delay: 0.6
                  }}
                >
                  <img src={Image4} alt="Something here" />
                  <p>Swimming</p>
                </motion.div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      {/*  */}

      {/* A-Z Fitness Assistance */}
      <section className='fitness_assistence'>
        <div className='service_head'>
          <p>Unlock the secret level! Explore our extra fitness services that are as exciting as an extra set of squats</p>
          <div className='service_content'>
            <h3><span>A-Z fitness</span> Assistance</h3>
            <p>In addition to our fitness services, we also offer nutrition and wellness resources to help you live a healthy, balanced lifestyle. We host events and workshops throughout the year, giving you the opportunity to learn from experts and connect with like-minded individuals.</p>
          </div>
        </div>

        <div className='service_container_main'>
          {
            // added slice for just demo purpose please remove it when go to production and when it get cleared
            fitnessdata?.map((val, i) => {
              return (
                <div key={i} className='service_cards' onClick={() => cardClick(val?.url)}>
                  <img src={`https://adm.8stepstudios.com/${val?.image}`} className="custom-image" />

                  <p className='service_para'>{val?.title}</p>
                  <p>{val?.short_desc}</p>
                  <button className='mainButton small-btn' onClick={() => cardClick(val?.url)} >LEARN MORE</button>
                </div>
              )
            })
          }
        </div>
        <button className='mainButton mt-5'>View More!</button>
      </section>
      {/*  */}

      {/* One-On-One */}
      <section className='oneOn_section'>
        <div className='oneOn_content'>
          <motion.h5
            initial={{ opacity: 0, x: -200 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{
              duration: 1.2,
              type: "spring",
              delay: 0
            }}
          >One-on-One</motion.h5>
          <motion.h4
            initial={{ opacity: 0, x: -100 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{
              duration: 1.2,
              type: "spring",
              delay: 0.5
            }}
          >By 8step Studios</motion.h4>
        </div>
        <div className='One_para'>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{
              duration: 1,
              type: "spring",
              delay: 0.7
            }}
          >Where the spotlight's yours and the gym's your private playground, It's a personal journey to fitness excellence with
            our expert trainers by your side. Here, we provide a whole ecosystem of holistic fitness, with every aspect taken care of.</motion.p>
          <div className='oneCard_container'>
            <motion.div
              initial={{ scale: 0 }}
              whileInView={{ scale: 1 }}
              viewport={{ once: true }}
              transition={{
                duration: 0.5,
                type: "linear",
                delay: 0.5
              }}
            >
              <div className='one_card' onClick={() => cardClick('/yoga')}>
                <img src={cardImage7} alt="cardImage7" />
                <span>Yoga</span>
              </div>
            </motion.div>
            <motion.div
              initial={{ scale: 0 }}
              whileInView={{ scale: 1 }}
              viewport={{ once: true }}
              transition={{
                duration: 0.5,
                type: "linear",
                delay: 0.7
              }}
            >
              <div className='one_card' onClick={() => cardClick('/zumba')}>
                <img src={cardImage8} alt="cardImage8" />
                <span>Zumba</span>
              </div>
            </motion.div>
            <motion.div
              initial={{ scale: 0 }}
              whileInView={{ scale: 1 }}
              viewport={{ once: true }}
              transition={{
                duration: 0.5,
                type: "linear",
                delay: 0.9
              }}
            >
              <div className='one_card' onClick={() => cardClick('/gym')}>
                <img src={cardImage9} alt="cardImage9" />
                <span>Gym</span>
              </div>
            </motion.div>
            <motion.div
              initial={{ scale: 0 }}
              whileInView={{ scale: 1 }}
              viewport={{ once: true }}
              transition={{
                duration: 0.5,
                type: "linear",
                delay: 1.1
              }}
            >
              <div className='one_card' >
                <img src={cardImage10} alt="cardImage10" />
                <span className='strength_cardss'>Strength</span>
              </div>
            </motion.div>
            <motion.div
              initial={{ scale: 0 }}
              whileInView={{ scale: 1 }}
              viewport={{ once: true }}
              transition={{
                duration: 0.5,
                type: "linear",
                delay: 1.3
              }}
            >
              <div className='one_card' onClick={() => cardClick('/hiit')}>
                <img src={cardImage11} alt="cardImage11" />
                <span>HIIT</span>
              </div>
            </motion.div>
            <motion.div
              initial={{ scale: 0 }}
              whileInView={{ scale: 1 }}
              viewport={{ once: true }}
              transition={{
                duration: 0.5,
                type: "linear",
                delay: 1.5
              }}
            >
              <div className='one_card'>
                <img src={cardImage12} alt="cardImage12" />
                <span>Pilates</span>
              </div>
            </motion.div>
          </div>
          <motion.div className='one_content'
            initial={{ opacity: 0, y: 80 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{
              duration: 1,
              type: "spring",
              delay: 0.5
            }}
          >
            <h5>Find the <span> Perfect Coach!</span></h5>
            <p>Our team of 8 step fitness studio trainers is dedicated to helping you achieve your fitness goals in a safe, effective, and fun manner. Our trainers have a wealth of knowledge and experience in various areas of fitness, including strength training, cardio, flexibility, and functional movement.</p>
            <button className='one_button' onClick={()=>navigate('/one-on-one')}>Get Your Coach Now! </button>
          </motion.div>
        </div>
      </section>
      {/*  */}

      {/* Fitness Heroes */}
      <section className='slider_section'>
        <Container>
          <motion.div className='fitness_content'
            initial={{ opacity: 0, scale: 1.25 }}
            whileInView={{ opacity: 1, scale: 1 }}
            viewport={{ once: true }}
            transition={{
              duration: 0.5,
              type: "linear",
              delay: 0.1
            }}
          >
            <p>Just like a GPS for fitness, our trainers will help you navigate
              the twists and turns of your wellness journey.</p>
            <h4>Meet Your <span>Fitness Heroes</span></h4>
          </motion.div>
        </Container>
        <motion.div
          initial={{ opacity: 0, y: -125 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{
            duration: 1,
            type: "spring",
            delay: 0.4
          }}
        >
          <Swiper
            slidesPerView={'auto'}
            spaceBetween={0}

            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            // loop={true}
            centeredSlides={true}
            navigation={true}
            modules={[Autoplay, Navigation]}
            className="fit-hero-slicer "
          >
            {
              team?.map((val, i) => {
                return (
                  <SwiperSlide key={i}>

                    <div className='card_body' key={i}>
                      <img className='card_image'
                        src={val?.image ? `https://adm.8stepstudios.com/${val?.image}` : 'https://developers.elementor.com/docs/assets/img/elementor-placeholder-image.png'}
                        // src='https://images.unsplash.com/photo-1715034136201-00dc287f7868?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
                        alt="cardimage" />
                      <div className='card_para'>
                        <p>{i + 1}/{team.length}</p>
                        <h6>{val?.name}</h6>
                        <h5>{val?.designation}</h5>
                        <p dangerouslySetInnerHTML={{ __html: val?.team_desc }}></p>

                      </div>
                    </div>
                  </SwiperSlide>
                )
              })
            }

          </Swiper>
          {/* <Slider
            className='fit-hero-slicer'
            centerMode={true}
            infinite={true}
            slidesToShow={1}
            centerPadding='420px'
            speed={600}
            // autoplay={true}
            prevArrow={<PrevArrow />}
            nextArrow={<NextArrow />}
          >
            {
              team?.map((val, i) => {
                return (
                  <div className='card_body' key={i}>
                    <img className='card_image' src={`https://adm.8stepstudios.com/${val?.image}`} alt="cardimage" />
                    <div className='card_para'>
                      <p>{i + 1}/{team.length}</p>
                      <h6>{val?.name}</h6>
                      <h5>{val?.designation}</h5>
                      <p dangerouslySetInnerHTML={{ __html: val?.team_desc }}></p>

                    </div>
                  </div>
                )
              })
            }
          </Slider> */}
        </motion.div>
        <Container>
          <div className='slide_para'>
            {/* <p>Your path to a fitter, healthier you is just a click away? Get started with our expert trainers now!"</p> */}
            <button className='mainButton mt-4' onClick={()=>navigate('/contact')}>Connect Now!</button>
          </div>
        </Container>
      </section>
      {/*  */}

      {/* Fitness Slam Book */}
      <section className='fitnessSlam_section'>
        <div className='slam'>
          <Container>
            <motion.div className='slam_content'
              initial={{ opacity: 0, scale: 1.25 }}
              whileInView={{ opacity: 1, scale: 1 }}
              viewport={{ once: true }}
              transition={{
                duration: 0.5,
                type: "linear",
                delay: 0.1
              }}
            >
              <p>You've read the journey, now see it unfold! a gallery filled with smiles, sweat, and success stories.
                It's the fitness slam book you've been waiting for!</p>
              <h4>Fitness <span> Slam Book </span></h4>
            </motion.div>
          </Container>
          <motion.div className='slide_master px-5'
            initial={{ opacity: 0, y: -125 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{
              duration: 1,
              type: "spring",
              delay: 0.4
            }}
          >
            <Slider {...slideFitness}>
              <div className='slide_images'><img src={Image6} alt="" /></div>
              <div className='slide_images'><img src={Image7} alt="" /></div>
              <div className='slide_images'><img src={Image8} alt="" /></div>
              <div className='slide_images'><img src={Image8} alt="" /></div>
            </Slider>
          </motion.div>
        </div>
        <div className='slam_para'>
          <p>"We embraced the burn, loved the results."- That’s what our clients say? See yourself!</p>
        </div>
      </section>
      {/*  */}

      {/* Client Testimonials */}
      <section className='client_Section'>
        <div className='Client_conatiner'>
          <motion.div className='client_content'
            initial={{ opacity: 0, scale: 1.25 }}
            whileInView={{ opacity: 1, scale: 1 }}
            viewport={{ once: true }}
            transition={{
              duration: 0.5,
              type: "linear",
              delay: 0.1
            }}
          >
            <h3>Client <span> Testimonials</span></h3>
          </motion.div>
          <motion.div className='client_slider'
            initial={{ opacity: 0, y: -125 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{
              duration: 1,
              type: "spring",
              delay: 0.4
            }}
          >
            <Slider {...slide} className='sm-master'>
              {
                data?.map((val, i) => {
                  
                  return (
                    <Card className='client_card'>
                      <Card.Body>
                        <Card.Img className='client_image' variant="top" src={`https://adm.8stepstudios.com/${val?.image}`} alt="Card Image 13" />
                        <Card.Title>{val?.client_name}</Card.Title>
                        <p>{val?.client_desc}</p>
                      </Card.Body>
                    </Card>
                  )
                })
              }
            </Slider>
          </motion.div>
          <div className='client_para'>
            <p>Ready to ditch the couch for crunches?</p>
            <button className='mainButton mt-4' onClick={buttonClick}>Join us today</button>
          </div>
        </div>
      </section>
      {/*  */}

      {/* encyclopedia_slider */}
      <section className='encyclopedia_slider'>
        <motion.div className='fitnessEnc'
          initial={{ opacity: 0, scale: 1.25 }}
          whileInView={{ opacity: 1, scale: 1 }}
          viewport={{ once: true }}
          transition={{
            duration: 0.5,
            type: "linear",
            delay: 0.1
          }}
        >
          {/* <p>Knowledge bytes from the fitness industry!</p> */}
          <h4>Fitness <span>Encyclopedia</span></h4>
        </motion.div>
        {/* {
          blogdata?.map((val, i) => {

          })
        } */}
        <motion.div className='fitnessEnc_slider px-5'
          initial={{ opacity: 0, y: -125 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{
            duration: 1,
            type: "spring",
            delay: 0.4
          }}
        >
          <Slider {...fit} className='sm-master'>
            <div>
              <Card className='fit_card' >
                <Card.Img variant="top" src={Image9} />
                <Card.Body className='p-4'>
                  <Card.Title>26 Oct, 2023</Card.Title>
                  <Card.Text>
                    YOUR FITNESS IS NOT ABOUT
                    BEING BETTER THAN ...
                  </Card.Text>
                  <button className='mainButton small-btn' variant="primary">Read More</button>
                </Card.Body>
              </Card>
            </div>
            <div>
              <Card className='fit_card'>
                <Card.Img variant="top" src={Image9} />
                <Card.Body className='p-4'>
                  <Card.Title>26 Oct, 2023</Card.Title>
                  <Card.Text>
                    YOUR FITNESS IS NOT ABOUT
                    BEING BETTER THAN ...
                  </Card.Text>
                  <button className='mainButton small-btn' variant="primary">Read More</button>
                </Card.Body>
              </Card>
            </div>
            <div>
              <Card className='fit_card'>
                <Card.Img variant="top" src={Image9} />
                <Card.Body className='p-4'>
                  <Card.Title>26 Oct, 2023</Card.Title>
                  <Card.Text>
                    YOUR FITNESS IS NOT ABOUT
                    BEING BETTER THAN ...
                  </Card.Text>
                  <button className='mainButton small-btn' variant="primary">Read More</button>
                </Card.Body>
              </Card>
            </div>
            <div>
              <Card className='fit_card'>
                <Card.Img variant="top" src={Image9} />
                <Card.Body className='p-4'>
                  <Card.Title>26 Oct, 2023</Card.Title>
                  <Card.Text>
                    YOUR FITNESS IS NOT ABOUT
                    BEING BETTER THAN ...
                  </Card.Text>
                  <button className='mainButton small-btn' variant="primary">Read More</button>
                </Card.Body>
              </Card>
            </div>
          </Slider>
        </motion.div>
      </section>
      {/* encyclopedia_slider */}

      {/* social media instagram */}
      <Instagram />
      {/*  */}
    </div>
  )
}

export default Home
