
import axios from 'axios';
const baseUrl = 'https://api.8stepstudios.com/api/user';
// const baseUrl = 'http://localhost:3010/api/user'


const AboutUS = async () => {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
    };
    try {
        const response = await axios.get(`${baseUrl}/about-us`, { headers });
        return response;
    } catch (error) {
        return error.message;
    }
};

const Menulist = async () => {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
    };
    try {
        const response = await axios.get(`${baseUrl}/menu`, { headers });
        return response;
    } catch (error) {
        return error.message;
    }
};


const Value = async () => {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
    };
    try {
        const response = await axios.get(`${baseUrl}/value`, { headers });
        return response;
    } catch (error) {
        return error.message;
    }
}



const visitUS = async () => {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
    };
    try {
        const response = await axios.get(`${baseUrl}/visit-us`, { headers });
        return response;
    } catch (error) {
        console.error('Error submitting Contact Us form:', error.message);
        return error.message;
    }
}





//contectus page

// const contectUS = async () => {
//     const headers = {
//         'Content-Type': 'application/x-www-form-urlencoded',
//     };
//     try {
//         const response = await axios.get(`${baseUrl}/contact`, { headers });
//         return response;
//     } catch (error) {
//         console.error('Error submitting Contact Us form:', error.message);
//         return error.message;
//     }
// };

const formSubmit = async (formData) => {
    try {
        const response = await axios.post(`${baseUrl}/contact-us`, formData,);
      
        return response;
    } catch (error) {
        throw new Error(error.response.data.message);
    }
};


const subscribQuerry = async (formData) => {
    try {
        const response = await axios.post(`${baseUrl}/subscrib_querry`, formData,);
      
        return response;
    } catch (error) {
        throw new Error(error?.response?.data?.message);
    }
};

const eventworkshopquerry = async(formdata)=>{
    try {
        const response = await axios.post(`${baseUrl}/event-workshope-email`,formdata)
        return response
    } catch (error) {
        throw new Error(error?.response?.data?.message)
    }
}
//workshope page

const workshope = async (formData) => {
    const headers = {
        'Content-Type': 'application/json',
    };
    try {
        const response = await axios.get(`${baseUrl}/workshope`, formData, { headers });
        return response;
    } catch (error) {
        throw new Error(error.response.data.message);

    }
};

const event = async (formData) => {
    const headers = {
        'Content-Type': 'application/json',
    };
    try {
        const response = await axios.get(`${baseUrl}/event`, formData, { headers });
        return response;
    } catch (error) {
        console.error('Error submitting Contact Us form:', error.message);
        return error.message;
    }
};

const eventInquery = async (data) => {
    const headers = {
        'Content-Type': "application/x-www-form-urlencoded",
    }
    try {
        const response = await axios.post(`${baseUrl}/add-event`, data, { headers });
        return response;
    } catch (error) {
        throw new Error(error.response.data.message);
    }
};


//program page

const Program = async () => {
    const headers = {
        'Content-Type': 'application/json',
    };
    try {
        const response = await axios.get(`${baseUrl}/program`, { headers });
        return response;
    } catch (error) {
        console.error('Error submitting Contact Us form:', error.message);
        return error.message;
    }
};

const Blogs = async () => {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
    };
    try {
        const response = await axios.get(`${baseUrl}/blog`, { headers });
        return response;
    } catch (error) {
        console.error('Error submitting Contact Us form:', error.message);
        return error.message;
    }
};

const ServiceAll = async () => {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
    };
    try {
        const response = await axios.get(`${baseUrl}/serviceAll`, { headers });
        return response;
    } catch (error) {
        console.error('Error submitting Contact Us form:', error.message);
        return error.message;
    }
};



//service/course page

const Course = async (data) => {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
    };
    try {
        const response = await axios.post(`${baseUrl}/course-details`, data,);
        return response;
    } catch (error) {
        console.error('Error submitting Contact Us form:', error.message);
        return error.message;
    }
};


const GetTestimonial = async (data) => {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
    };
    try {
        const response = await axios.post(`${baseUrl}/user-testimonial`, data,);
        return response;
    } catch (error) {
        console.error('Error submitting Contact Us form:', error.message);
        return error.message;
    }
};

const membarship = async () => {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
    };
    try {
        const response = await axios.get(`${baseUrl}/plan`, { headers });
        return response;
    } catch (error) {
        console.error('Error submitting Contact Us form:', error.message);
        return error.message;
    }
}

const Clubmembarship = async () => {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
    };
    try {
        const response = await axios.get(`${baseUrl}/club-membership`, { headers });
        return response;
    } catch (error) {
        return error.message;
    }
}

//home page
const Fitness = async (data) => {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
    };
    try {
        const response = await axios.get(`${baseUrl}/fitness`, { headers });
        return response;
    } catch (error) {
        return error.message;
    }
}

const Testimonial = async () => {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
    };
    try {
        const response = await axios.get(`${baseUrl}/client-testimonial`, { headers });
        return response;
    } catch (error) {
        return error.message;
    }
}

const Teammember = async () => {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
    };
    try {
        const response = await axios.get(`${baseUrl}/team`, { headers });
        return response;
    } catch (error) {
        return error.message;
    }
}

//one by one page
const OneBenifit = async () => {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
    };
    const abortController = new AbortController();
    const signal = abortController.signal;
    try {
        const response = await axios.get(`${baseUrl}/one-benifit`, {
            headers,
            signal
        });
        return response;
    } catch (error) {
        if (error.name === 'AbortError') {
           
        } else {
            console.error('Error submitting Contact Us form:', error.message);
        }
        return error.message;
    }
};

const OneAbout = async () => {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
    };
    const abortController = new AbortController();
    const signal = abortController.signal;
    try {
        const response = await axios.get(`${baseUrl}/one-about`, {
            headers,
            signal
        });
        return response;
    } catch (error) {
        if (error.name === 'AbortError') {
          
        } else {
            console.error('Error submitting Contact Us form:', error.message);
        }
        return error.message;
    }
};

const OneResult = async () => {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
    };
    const abortController = new AbortController();
    const signal = abortController.signal;
    try {
        const response = await axios.get(`${baseUrl}/one-rusult`, {
            headers,
            signal
        });
        return response;
    } catch (error) {
        if (error.name === 'AbortError') {
           
        } else {
            console.error('Error submitting Contact Us form:', error.message);
        }
        return error.message;
    }
};

const OneSession = async () => {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
    };
    // const abortController = new AbortController();
    // const signal = abortController.signal;
    try {
        const response = await axios.get(`${baseUrl}/one-sesion`, {
            headers,
            // signal 
        });
        return response;
    } catch (error) {
        
        return error.message;
    }
};


const aboutfaq = async (data) => {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
    };
    try {
        const response = await axios.post(`${baseUrl}/faq`,data, { headers });
        return response;
    } catch (error) {
        return error.message;
    }
}


export const ApiService = {
    Program,
    membarship,
    Course,
    Teammember,
    OneSession,
    OneBenifit,
    OneAbout,
    OneResult,
    aboutfaq,
    Testimonial,
    Menulist,
    Fitness,
    Value,
    Blogs,
    event,
    workshope,
    formSubmit,
    visitUS,
    AboutUS,
    eventInquery,
    Clubmembarship,
    GetTestimonial,
    ServiceAll,
    subscribQuerry,
    eventworkshopquerry
};

