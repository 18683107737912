import React, { useState, useEffect } from 'react';
import '../../assets/Css/Workshops.css';
import { ApiService } from '../../service/ApiService';
import EnquiryImg from '../../assets/Images/workshop_images/img4.png';
import Container from 'react-bootstrap/Container';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from 'react-bootstrap/Accordion';
import { Badge, Calendar, Modal, Tooltip, Form, Input } from 'antd';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import eventImg from '../../assets/Images/img8.png'

const getListData = (value) => {
  let listData;
  switch (value.date()) {
    case 8:
      listData = [
        {
          type: 'warning',
          content: 'This is warning event.',
        },
      ];
      break;
    case 10:
      listData = [
        {
          type: 'success',
          content: 'This is usual event.',
        },
      ];
      break;
    case 15:
      listData = [
        {
          type: 'error',
          content: 'This is error event 4.',
        },
      ];
      break;
    default:
  }
  return listData || [];
};

const getMonthData = (value) => {
  if (value.month() === 8) {
    return 1394;
  }
};

function Workshops() {
  const [evendata, setEventData] = useState([])
  const [workshop, setWorkshop] = useState([])
  const [faq, setFaq] = useState([])
  const [errors, setErrors] = useState({});
  const [form] = Form.useForm();
  const [emailData, setEmailData] = useState(null)
  const [Data, setData] = useState({
    name: '',
    whatsappNumber: '',
    eventName: ''
  });

  const notifySuccess = (message) => {
    toast.success(message);
  };

  const notifyError = (message) => {
    toast.error(message);
  };

  const navigate = useNavigate()

  const validatePhone = (phone) => {
    const phoneRegex = /^[0-9]{10}$/;
    return phoneRegex?.test(phone);
  };

  const GetWorkshop = async () => {
    try {
      const response = await ApiService.workshope();
      if (response?.data?.status == 1) {
        setWorkshop(response?.data?.data)
      } else {
        setWorkshop([])
      }
    } catch (error) {
      console.log(error, 'api erorr')
    }
  }

  const GetEvent = async () => {
    try {
      const response = await ApiService.event();
      if (response?.data?.status == 1) {
        setEventData(response?.data?.data)
      } else {
        setEventData([])
      }
    } catch (error) {
      console.log(error, 'api erorr')
    }
  }



  const Getfaq = async () => {
    try {
      let currentPagePath = window.location.pathname;
      if (currentPagePath.charAt(0) === '/') {
        currentPagePath = currentPagePath.substring(1);
      }
      const data = new URLSearchParams();
      data.append('page_name', currentPagePath);

      const response = await ApiService.aboutfaq(data);
      if (response?.data?.status == 1) {
        setFaq(response?.data?.data)
      } else {
        setFaq([])
      }
    } catch (error) {
      console.log(error, 'api error')
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Perform strict validation for WhatsApp number field
    if (name === 'whatsappNumber' && isNaN(value)) {
      return; // Ignore non-numeric input
    }

    setData((prevData) => ({
      ...prevData,
      [name]: value
    }));

    // Check for empty fields
    if (value.trim() === '') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: 'This field is required'
      }));
    } else if (name === 'whatsappNumber') {
      if (value.trim() !== '' && !validatePhone(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: 'Please enter a valid 10-digit phone number'
        }));
      } else {
        // Clear error if phone number is valid
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: ''
        }));
      }
    }

  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!Data?.name || !Data?.eventName || !Data?.whatsappNumber) {
      notifyError('All fields are required');
      return;
    }

    try {
      const formData = new URLSearchParams();
      formData.append('event_user_name', Data.name);
      formData.append('event_name', Data.eventName);
      formData.append('contact_no', Data.whatsappNumber);
      const response = await ApiService.eventInquery(formData);
      if (response?.data?.status == 1) {
        setData({
          name: '',
          whatsappNumber: '',
          eventName: ''
        });
        notifySuccess(response?.data?.message);
      } else {
        setData({
          name: '',
          whatsappNumber: '',
          eventName: ''
        });
        notifyError(response?.data?.message);
      }

    } catch (error) {
      notifyError(`${error.message}`);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    GetWorkshop();
    GetEvent();
    Getfaq()
  }, [])
  
  const Formsubmit = async () => {
    const formData = new URLSearchParams();
    formData.append('email_id', emailData);
      try {
        const response = await ApiService.eventworkshopquerry(formData);
        if (response?.data?.status === 1) {
          form.resetFields();
          setEmailData(null)
          notifySuccess('Thank you for your valuable time. We will get in touch with you shortly.');
        }else{
          notifyError('Enter Your Email');
        }
      } catch (error) {
        notifyError(error.message);
      }
  };



  const monthCellRender = (value) => {
    const num = getMonthData(value);
    return num ? (
      <div className="notes-month">
        <section>{num}</section>
        {/* <span>Backlog number</span> */}
      </div>
    ) : null;
  };

  const dateCellRender = (value) => {
    const listData = getListData(value);
    return (
      <ul className="events">
        {listData.map((item) => (
          <li key={item.content}>
            <Badge status={item.type} text={item.content} />
          </li>
        ))}
      </ul>
    );
  };

  const cellRender = (current, info) => {
    if (info.type === 'date') return dateCellRender(current);
    if (info.type === 'month') return monthCellRender(current);
    return info.originNode;
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };



  return (
    <>
      <div className='background_workshop'>
        <div className='bg_content'>
          <h2>Beyond the gym grind </h2>
          <h3>Discover Our World of Workshops And Events</h3>
          <p>Dive into a world where fitness meets festivity! From the exhilarating Health and Rally to the scenic Yoga on Hills, we've got your holistic fitness covered. These workshops aren't just events; they're adventures lasting 2-3 days to keep pace with your energetic spirit. Whether you're trekking into new fitness dimensions or grooving at fitness parties, we're here to keep your attention high and your enthusiasm flowing. Embrace the excitement—your fitness journey just became a celebration!</p>
          <button className='mainButton mt-5' onClick={() => navigate('/contact')}> Browse Workshops!</button>
        </div>
      </div>

      <section className='ourWorkshop_section'>
        <Container>
          <div className='our_workshops'>
            <h1>Workshops</h1>
            <h2>Our Workshops</h2>
            <div className='ourWorkshop_card_cont'>
              {
                workshop?.map((val, i) => {
                  return (
                    <div className='workshope_cards' key={i}>
                      <div>
                        <img className='card_image' src={`https://adm.8stepstudios.com/${val?.image}`} alt="cardimage" />
                      </div>
                      <h3>{val?.work_name}</h3>
                      <p>{val?.date}</p>
                      <div className='participant_div'>
                        <h4>Participants: <span>{val?.participants_no}</span>  </h4>
                        <h4> Best Student Name: <span>{val?.best_std_name}</span> </h4>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </Container>
      </section>

      <section className='ourEvents_section'>
        <Container>
          <div className='ourEvents_content'>
            <h1>Events</h1>
            <h2> Our Events </h2>
            <div className='events_cards_cont'>
              {
                evendata?.map((val, i) => {
                  return (
                    <div key={i}>
                      <div className='grid_image'>
                        <img className='card_image' src={`https://adm.8stepstudios.com/${val?.image[0]}`} alt="cardimage" />
                      </div>
                      <div className='grid_container'>
                        {val?.image?.map((element, i) => {
                          // console.log(element,"element")
                          // console.log(i,"i")
                          return (
                            i != 0 ? <img key={i + 1} src={`https://adm.8stepstudios.com/${element}`} alt="" /> : ''
                          );
                        })
                        }
                      </div>
                      <div className='grid_content'>
                        <p>{val?.dataValues?.event_name}</p>
                        <div className='grid_para'><p>{val?.dataValues?.event_date}</p></div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </Container>
      </section>

      <section className='event_section'>
        <Container>
          <div className='enquiry_head'>
            <h1>Enquiries</h1>
            <h2>Event <span>Enquiry</span></h2>
            <Row>
              <Col xs={12} md={6}>
                <div className='contact_container'>
                  <p>Contact us:</p>
                  <div className='contact_form'>
                    <form onSubmit={handleSubmit}>
                      <input
                        type="text"
                        name='name'
                        placeholder='Name'
                        autoComplete='off'
                        value={Data?.name}
                        onChange={handleInputChange}
                      />
                      {errors?.name && <p className="error">{errors?.name}</p>}
                      <input
                        type="tel"
                        name='whatsappNumber'
                        placeholder='Whatsapp Number'
                        autoComplete='off'
                        value={Data?.whatsappNumber}
                        onChange={handleInputChange}
                      />
                      {errors?.whatsappNumber && <p className="error">{errors?.whatsappNumber}</p>}
                      <input
                        type="text"
                        name='eventName'
                        placeholder='Event Name'
                        autoComplete='off'
                        value={Data?.eventName}
                        onChange={handleInputChange}
                      />
                      {errors?.eventName && <p className="error" >{errors?.eventName}</p>}

                      <button type="submit" className='mainButton mt-4'>Send</button>
                    </form>

                  </div>

                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className='enquiry_image'>
                  <img src={EnquiryImg} alt="Something Here" />
                </div>
              </Col>
            </Row>
            <div className='enquiry_content'>
              <p> From sunrise yoga to sunset runs, <h3>our calendar ensures every moment is a step towards a healthier you!</h3></p>
            </div>
          </div>
        </Container>
      </section>

      <section className='eveCalendar_section'>
        <Container>
          <div className='calendar_workshop'>
            <h1>Calendar</h1>
            <h3> Event Calendar </h3>
            <div className='calender_google'>
              <div className='calender_sideHead'>
                <h5>21 May,2024</h5>
                <span>Tuesday</span>
              </div>
              <Calendar onSelect={showModal} cellRender={cellRender} />
            </div>
            <p>Don't just exercise, celebrate! Click to join our exclusive workshops and events to make fitness a joyful experience</p>
            <button className='mainButton small-btn mt-4' onClick={() => navigate('/contact')}>Join Now!</button>
          </div>
        </Container>
      </section>

      <section className='result_faq'>
        <Container>
          <div className='faqs_oneon'>
            <h1>FAQ</h1>
            <h4> FAQs</h4>
            <div className='faqs_ques_ans'>
              <Accordion defaultActiveKey="0">
                {faq?.map((val, i) => (
                  <Accordion.Item eventKey={i.toString()} key={i}>
                    <Accordion.Header>{val?.faq_question}</Accordion.Header>
                    <Accordion.Body>{val?.faq_answer}</Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
          </div>
        </Container>
      </section>

      <Modal
        title="Event Info"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1250}
        className='eventinfoModel'
        footer={
          <div className='eventBtn'>
            <button className='submitBtn' onClick={Formsubmit}>Submit</button>
            <button className='cancelBtn' >Cancel</button>
          </div>
        }>
        <div className='eventModel_content'>
          <div className='chip_workshop'>
            <div className="heading_chips">
              <h4>Vinayasa Yoga Workshop</h4>
              <div className='share_allBTn'>
                <Tooltip title="Share Event Info"><svg width="21" height="21" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M26 4L44 22L26 39V28C12 28 6 43 6 43C6 26 11 15 26 15V4Z" fill="none" stroke="#333" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" /></svg></Tooltip>
                <Tooltip title="Event Type"><span>Workshop</span></Tooltip>
              </div>
            </div>
            <div className='event_descrption'>
              <h4> Description</h4>
              <span>When requiring users to interact with the application, but without jumping to a new page and interrupting the user's workflow, you can use Modal to create a new floating layer over the current page to get user feedback or display information.</span>
            </div>
            <div className='event_descrption'>
              {/* <h4>Date and Timing</h4> */}
              <span><svg width="18" height="18" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M23.9998 44.3332C34.1251 44.3332 42.3332 36.1251 42.3332 25.9999C42.3332 15.8747 34.1251 7.66656 23.9998 7.66656C13.8746 7.66656 5.6665 15.8747 5.6665 25.9999C5.6665 36.1251 13.8746 44.3332 23.9998 44.3332Z" fill="none" stroke="#333" stroke-width="4" stroke-linejoin="round" /><path d="M23.7594 15.3536L23.7582 26.3624L31.5305 34.1347" stroke="#333" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" /><path d="M4 9.00001L11 4.00001" stroke="#333" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" /><path d="M44 9.00001L37 4.00001" stroke="#333" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" /></svg>&nbsp; 22 May 2024, Wednesday & 9:00am-1:00pm</span>
            </div>
            <div className='event_descrption'>
              <h4> Address</h4>
              <span>Shivgyan Luxora, Parivahan Marg, behind hotel rajmahal palace, Shivaji Nagar, C Scheme, Civil Lines, Jaipur, Rajasthan 302001</span>
            </div>
            <div className='heading_participate'>
              <div className='event_descrption'>
                <h4>Total Participates</h4>
                <span>145 Members</span>
              </div>
              <div className='event_descrption'>
                <span><img src={eventImg} /> &nbsp; Rohit Sharma</span>
                <span><img src={eventImg} /> &nbsp; Rohit Sharma</span>
              </div>
            </div>
            <div className='subscribe_drawer mt-2'>
              <Form
                form={form}
              
              >
                <Form.Item
                  label="If you want to Join the event"
                  name="email_id"
                  rules={[
                    { required: true, message: 'Please enter your email!' },
                    { type: 'email', message: 'Please enter a valid email!' }
                  ]}

                >
                  <Input placeholder='Enter your Email' onChange={(e) => setEmailData(e.target.value)} />
                </Form.Item>
              </Form>
            </div>
          </div>
          <div class="gallery">
            <div class="gallery__item gallery__item--hor">
              <img src="https://source.unsplash.com/random/500x500" alt="" />
            </div>
            <div class="gallery__item">
              <img src="https://source.unsplash.com/collection/190727/300x300" alt="" />
            </div>
            <div class="gallery__item">
              <img src="https://source.unsplash.com/user/erondu/600x600" alt="" />
            </div>
            <div class="gallery__item gallery__item--vert">
              <img src="https://source.unsplash.com/user/timmarshall/300x300" alt="" />
            </div>
            <div class="gallery__item">
              <img src="https://source.unsplash.com/user/joannecaselynsuarez/300x300" alt="" />
            </div>
            <div class="gallery__item">
              <img src="https://source.unsplash.com/user/liamsimpson/600x600" alt="" />
            </div>
            <div class="gallery__item">
              <img src="https://source.unsplash.com/user/stephanvancephoto/300x300" alt="" />
            </div>
            <div class="gallery__item">
              <img src="https://source.unsplash.com/user/beataratuszniak/300x300" alt="" />
            </div>
            <div class="gallery__item">
              <img src="https://source.unsplash.com/user/tonill/300x300" alt="" />
            </div>
          </div>
        </div>
      </Modal>

    </>
  )
}

export default Workshops