import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React from 'react';
import "./App.css"

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// Pages
import Navbar from './Components/Header/Navbar.jsx';
import Footer from './Components/Footer/Footer.jsx';
import Home from './Pages/home/Home.jsx';
import OneonOne from './Pages/one/Oneon.jsx';
// import Service from './Pages/services/Service.jsx'
import Programs from './Pages/programs/programs.jsx';
import Workshops from './Pages/workshops/workshops.jsx';
import About from './Pages/about/About.jsx';
import Contact from './Pages/contact/Contact.jsx';

import Membership from './Pages/membership/Membership.jsx';
import Branch from './Pages/branch/Branch.jsx';
import { ToastContainer } from 'react-toastify';
import BlogSec from './Pages/blog/Blogs.jsx';
import Blogs from './Pages/blog/BlogSection.jsx';
import ServiceAll from './Pages/home/ServiceAll.jsx';

import "./assets/Css/responsive.css"


const App = () => {
  return (
    <>
      <ToastContainer position="bottom-center" autoClose={1500} />
      <Router>
        <Navbar />

        <Routes>
          <Route index element={<Home />} />
          <Route path="one-on-one" element={<OneonOne />} />
          <Route path="membership" element={<Membership />} />
          <Route path="branch" element={<Branch />} />
          <Route path="program" element={<Programs />} />
          <Route path="workshop" element={<Workshops />} />
          <Route path="about-us" element={<About />} />
          <Route path="blogSec" element={<BlogSec />} />
          <Route path="blogs" element={<Blogs />} />
          <Route path="contact" element={<Contact />} />
          <Route path="serviceAll" element={<ServiceAll />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
};

export default App;

