// import React, { useState } from 'react';
// import { Drawer, Form, Input, Select, Modal } from 'antd';
// import Lottie from 'react-lottie';
// import * as animationData from '../assets/Images/thankgif.json'
// import 'react-toastify/dist/ReactToastify.css';
// import { toast } from 'react-toastify';
// import { ApiService } from '../service/ApiService'
// const SubscribeDrawer = ({ open, onClose }) => {
//     const [isModalOpen, setIsModalOpen] = useState(false);
//     const [Data, setData] = useState({
//         name: '',
//         email: '',
//         phone: '',
//         gender:'',
//         branch:'',
//         plan_duration:'',
//         plan_id:'',
//         message: ''
//       });


//     const notifySuccess = (message) => {
//         console.log(message, 'message')
//         toast.success(message);
//       };

//       const notifyError = (message) => {
//         toast.error(message);
//       };
//     const showModal = () => {
//         setIsModalOpen(false);
//     };
//     const handleOk = () => {
//         setIsModalOpen(false);
//     };
//     const handleCancel = () => {
//         setIsModalOpen(false);
//     };


//     const handlePhoneInputKeyPress = (e) => {
//         const charCode = e.which ? e.which : e.keyCode;
//         if (charCode < 48 || charCode > 57) {
//             e.preventDefault();
//         }
//     };


//    const Formsubmit = async()=>{
//     const formData = new URLSearchParams();
//     formData.append('name', Data.name);
//     formData.append('email', Data.email);
//     formData.append('phone', Data.phone);
//     formData.append('gender',Data.gender);
//     formData.append('plan_id',Data.plan_id);
//     formData.append('plan_duration',Data.plan_duration);
//     formData.append('branch',Data.branch);
//     formData.append('message', Data.message);
//     const response = await ApiService.subscribQuerry(formData)
//    }


//     const defaultOptions = {
//         loop: true,
//         autoplay: true,
//         animationData: animationData,
//         rendererSettings: {
//             preserveAspectRatio: 'xMidYMid slice'
//         }
//     };



//     return (
//         <>
//             <Drawer title="Provide your information"
//                 onClose={onClose}
//                 open={open}
//                 width={500}
//                 className='subscribe_drawer'
//                 footer={<div className='subscibe_buttons'>
//                     <button className='saveBtn' >Save</button>
//                     <button className='cancelBtn' onClick={onClose}>Cancel</button>
//                 </div>}>
//                 <Form
//                 //   onFinish={onFinish}
//                 //   onFinishFailed={onFinishFailed}
//                   autoComplete="off"
//                 >
//                     <Form.Item
//                         label="Full Name"
//                         name="fullName"
//                         rules={[
//                             {
//                                 required: true,
//                                 message: 'Please enter your name!',
//                             },
//                         ]}

//                     >
//                         <Input placeholder='Enter your name' />
//                     </Form.Item>
//                     <Form.Item
//                         label="Email"
//                         name="email"
//                         rules={[
//                             { required: true, message: 'Please enter your email!' },
//                             { type: 'email', message: 'Please enter a valid email!' }
//                         ]}

//                     >
//                         <Input placeholder='Enter your email' />
//                     </Form.Item>
//                     <Form.Item
//                         label="Phone Number"
//                         name="phoneNumber"
//                         rules={[
//                             { required: true, message: 'Please enter your phone number!' },
//                             { pattern: /^\d{10}$/, message: 'Phone number must be 10 digits!' }
//                         ]}

//                     >
//                         <Input placeholder='Enter your phone number' onKeyDown={handlePhoneInputKeyPress} />
//                     </Form.Item>
//                     <Form.Item
//                         label="Gender"
//                         name="gender"
//                         rules={[
//                             {
//                                 required: true,
//                                 message: 'Please enter your gender!',
//                             },
//                         ]}
//                     >
//                         <Select placeholder="Enter Gender">
//                             <Select.Option value="male">Male</Select.Option>
//                             <Select.Option value="female">Female</Select.Option>
//                             <Select.Option value="other">Other</Select.Option>
//                         </Select>
//                     </Form.Item>
//                     <Form.Item
//                         label="Branch"
//                         name="branch"
//                         rules={[
//                             {
//                                 required: true,
//                                 message: 'Please enter your Branch!',
//                             },
//                         ]}
//                     >
//                         <Select placeholder="Enter Branch">
//                             <Select.Option value="male">Banipark Branch</Select.Option>
//                             <Select.Option value="female">C-Scheme Branch</Select.Option>
//                         </Select>
//                     </Form.Item>
//                     <Form.Item
//                         label="Membership Plans"
//                         name="memberPlan"
//                         rules={[
//                             {
//                                 required: true,
//                                 message: 'Please enter your membership plan!',
//                             },
//                         ]}
//                     >
//                         <Select placeholder="Enter your Membership Plan">
//                             <Select.Option value="memberPlan">Membership Plan</Select.Option>
//                             <Select.Option value="clubPlan">Club Membership Plan</Select.Option>
//                         </Select>
//                     </Form.Item>
//                     <Form.Item
//                         label="Period of time"
//                         name="memberTime"
//                         rules={[
//                             {
//                                 required: true,
//                                 message: 'Please enter your period of time!',
//                             },
//                         ]}
//                     >
//                         <Select placeholder="Enter your Period of time">
//                             <Select.Option value="oneMonth">1 Month Plan</Select.Option>
//                             <Select.Option value="threeMonth">3 Month Plan</Select.Option>
//                             <Select.Option value="sixMonth">6 Month Plan</Select.Option>
//                             <Select.Option value="twelveMonth">12 Month Plan</Select.Option>
//                         </Select>
//                     </Form.Item>
//                     <Form.Item
//                         label="Any queries"
//                         name="anyQueries"
//                         rules={[
//                             {
//                                 required: true,
//                                 message: 'Please ask your question!',
//                             },
//                         ]}
//                     >
//                         <Input.TextArea rows={3} />
//                     </Form.Item>
//                 </Form>
//             </Drawer>

//             <Modal open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null} className='thanks_modal'>
//                 <Lottie options={defaultOptions}
//                     height={200}
//                     width={200} />
//                 <p>Thank you for your valuable time. We will get in touch with you shortly.</p>
//             </Modal>
//         </>
//     )
// };

// export default SubscribeDrawer;




import React, { useState } from 'react';
import { Drawer, Form, Input, Select, Modal, Button, message } from 'antd';
import Lottie from 'react-lottie';
import * as animationData from '../assets/Images/thankgif.json';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { ApiService } from '../service/ApiService';

const { Option } = Select;

const SubscribeDrawer = ({ open, onClose }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [form] = Form.useForm();

    const notifySuccess = (message) => {
        toast.success(message);
    };

    const notifyError = (message) => {
        toast.error(message);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handlePhoneInputKeyPress = (e) => {
        const charCode = e.which ? e.which : e.keyCode;
        if (charCode < 48 || charCode > 57) {
            e.preventDefault();
        }
    };

    const Formsubmit = async (values) => {
        const formData = new URLSearchParams();
        Object.keys(values).forEach(key => {
            formData.append(key, values[key]);
        });

        try {
            const response = await ApiService.subscribQuerry(formData);
            console.log(response?.data?.status, 'message')
            if (response?.data?.status == 1) {
                // notifySuccess("Subscription successful!");
                setIsModalOpen(true);
                form.resetFields();
                // Close modal after 5 seconds
                setTimeout(() => {
                    setIsModalOpen(false);
                }, 5000);
            } else {
                notifyError(response?.data?.message || "Subscription failed. Please try again.");
            }
        } catch (error) {
            notifyError(error.message);
        }
    };

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <>
            <Drawer
                title="Provide your information"
                onClose={onClose}
                open={open}
                width={500}
                className='subscribe_drawer'
                footer={
                    <div className='subscibe_buttons'>
                        <Button type='primary' onClick={() => form.submit()}>Save</Button>
                        <Button onClick={onClose}>Cancel</Button>
                    </div>
                }
            >
                <Form
                    form={form}
                    onFinish={Formsubmit}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Full Name"
                        name="name"
                        rules={[{ required: true, message: 'Please enter your name!' }]}
                    >
                        <Input placeholder='Enter your name' />
                    </Form.Item>
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                            { required: true, message: 'Please enter your email!' },
                            { type: 'email', message: 'Please enter a valid email!' }
                        ]}
                    >
                        <Input placeholder='Enter your email' />
                    </Form.Item>
                    <Form.Item
                        label="Phone Number"
                        name="phone"
                        rules={[
                            { required: true, message: 'Please enter your phone number!' },
                            { pattern: /^\d{10}$/, message: 'Phone number must be 10 digits!' }
                        ]}
                    >
                        <Input placeholder='Enter your phone number' onKeyDown={handlePhoneInputKeyPress} />
                    </Form.Item>
                    <Form.Item
                        label="Gender"
                        name="gender"
                        rules={[{ required: true, message: 'Please select your gender!' }]}
                    >
                        <Select placeholder="Select Gender">
                            <Option value="male">Male</Option>
                            <Option value="female">Female</Option>
                            <Option value="other">Other</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Branch"
                        name="branch"
                        rules={[{ required: true, message: 'Please select your branch!' }]}
                    >
                        <Select placeholder="Select Branch">
                            <Option value="banipark">Banipark Branch</Option>
                            <Option value="cscheme">C-Scheme Branch</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Membership Plans"
                        name="plan_name"
                        rules={[{ required: true, message: 'Please select your membership plan!' }]}
                    >
                        <Select placeholder="Select Membership Plan">
                            <Option value="regular-membership">Membership Plan</Option>
                            <Option value="club-Membership">Club Membership Plan</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Period of time"
                        name="plan_duration"
                        rules={[{ required: true, message: 'Please select the period of time!' }]}
                    >
                        <Select placeholder="Select Period of time">
                            <Option value="1month">1 Month Plan</Option>
                            <Option value="3months">3 Month Plan</Option>
                            <Option value="6months">6 Month Plan</Option>
                            <Option value="12months">12 Month Plan</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Any queries"
                        name="message"
                    >
                        <Input.TextArea rows={3} placeholder='Enter your queries' />
                    </Form.Item>
                </Form>
            </Drawer>

            <Modal open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null} className='thanks_modal'>
                <Lottie options={defaultOptions} height={200} width={200} />
                <p>Thank you for your valuable time. We will get in touch with you shortly.</p>
            </Modal>
        </>
    );
};

export default SubscribeDrawer;
