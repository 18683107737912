import React from 'react'
import '../../assets/Css/Contact.css'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container'
import Accordion from 'react-bootstrap/Accordion'
import TeleIcon from '../../assets/Images/Contact_Images/Icons/tel.png'
import MapIcon from '../../assets/Images/Contact_Images/Icons/map.png'
import InstaIcon from '../../assets/Images/Contact_Images/Icons/Instagram.png'
import FbIcon from '../../assets/Images/Contact_Images/Icons/facebook.png'
import YtIcon from '../../assets/Images/Contact_Images/Icons/youtube.png'
import PinIcon from '../../assets/Images/Contact_Images/Icons/Pin.png'
import LinkIcon from '../../assets/Images/Contact_Images/Icons/LinkedIn.png'
import Image10 from '../../assets/Images/grid imges/img1.png'
import Image11 from '../../assets/Images/grid imges/img2.png'
import Image12 from '../../assets/Images/grid imges/img3.png'
import Image13 from '../../assets/Images/grid imges/img4.png'
import Image14 from '../../assets/Images/grid imges/img5.png'
import Image15 from '../../assets/Images/grid imges/img6.png'
import Image16 from '../../assets/Images/grid imges/img7.png'
import Image17 from '../../assets/Images/grid imges/img8.png'
import Image18 from '../../assets/Images/grid imges/img9.png'
import Vec from '../../assets/Images/Contact_Images/Icons/vec.png'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { ApiService } from '../../service/ApiService'
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

function Contact() {
  const notifySuccess = (message) => {
    console.log(message, 'message')
    toast.success(message);
  };

  const notifyError = (message) => {
    toast.error(message);
  };
  const [address, setAddress] = useState([])
  const [faq, setFaq] = useState([])
  const [errors, setErrors] = useState({});
  const [Data, setData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });
  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    return emailRegex?.test(email);
  };

  const validatePhone = (phone) => {
    const phoneRegex = /^[0-9]{10}$/;
    return phoneRegex?.test(phone);
  };
  const visitUS = async () => {
    try {
      const response = await ApiService.visitUS();

      if (response) {
        setAddress(response?.data?.data)
      } else {
        setAddress([])
      }
    } catch (error) {
      console.log(error.message, 'api erorr')
    }
  }

  const Faq = async () => {
    try {

      let currentPagePath = window.location.pathname;
      if (currentPagePath.charAt(0) === '/') {
        currentPagePath = currentPagePath.substring(1);
      }
      const data = new URLSearchParams();
      data.append('page_name', currentPagePath);

      const response = await ApiService.aboutfaq(data);
      if (response) {
        setFaq(response?.data?.data)
      } else {
        setFaq([])
      }
    } catch (error) {
      console.log(error.message, 'api erorr')
    }
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    // Perform strict validation for phone number field
    if (name === 'phone' && isNaN(value)) {
      return; // Ignore non-numeric input
    }
    setData((prevData) => ({
      ...prevData,
      [name]: value
    }));

    // Check for empty fields
    if (value.trim() === '') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: 'This field is required'
      }));
    } else {
      // Clear error if field is not empty
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: ''
      }));
    }
    // Perform additional field-level validation (e.g., email, phone)
    if (name === 'email' && !validateEmail(value)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: 'Please enter a valid email address'
      }));
    }
    else if (name === 'phone') {
      if (value.trim() !== '' && !validatePhone(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: 'Please enter a valid 10-digit phone number'
        }));
      } else {
        // Clear error if phone number is valid
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: ''
        }));
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!Data?.name ||  !Data?.email || !Data?.phone || !Data?.message) {
      notifyError('All fields are required');
      return;
    }

    try {
      const formData = new URLSearchParams();
      formData.append('name', Data.name);
      formData.append('email', Data.email);
      formData.append('phone', Data.phone);
      formData.append('message', Data.message);
      const response = await ApiService.formSubmit(formData);
      if (response?.data?.status === 1) {
        setData({
          name: '',
          email: '',
          phone: '',
          message: ''
        });
        notifySuccess(response?.data?.message);
      } else {
        setData({
          name: '',
          email: '',
          phone: '',
          message: ''
        });
        notifyError(response?.data?.message);
      }
    } catch (error) {
      notifyError(`${error.message}`);
    }
  };





  useEffect(() => {
    window.scrollTo(0, 0);
    visitUS()
    Faq()
  }, [])



  return (
    <>
      <section className='contact_section' >
        <Container>
          <div className='cont'>
            <Row>
              <Col>
                {
                  address?.slice(0, 1)?.map((item, i) => {
                    return (
                      <>
                        <div className='cont_details' key={i}>
                          <h1>Contact</h1>
                          <h2>Get in Touch</h2>
                          <p>Welcome to 8 Step Studios, the biggest fitness center in Jaipur! At 8 Step Studios, we are dedicated to helping you achieve your health and fitness goals.</p>
                        </div>
                        <div className='cont_call'>
                          <img src={TeleIcon} alt="" />
                          <p><a href={`tel:${item?.visit_contact_no}`}>{item?.visit_contact_no}</a></p>
                        </div>
                        <div className='cont_map'>
                          <img src={MapIcon} alt="" />
                          <p>{item?.visit_address}</p>
                        </div>
                      </>
                    )
                  })
                }
              </Col>
              <Col>
                <div className='conta_form'>
                  <form onSubmit={handleSubmit}>
                    <input
                      type="text"
                      name='name'
                      placeholder='Name'
                      autoComplete='off'
                      value={Data?.name}
                      onChange={handleChange}
                    />
                    {errors?.name && <p className="error">{errors?.name}</p>}
                    <input
                      type="text"
                      name='email'
                      placeholder='Email'
                      autoComplete='off'
                      value={Data?.email}
                      onChange={handleChange}
                    />
                    {errors?.email && <p className="error">{errors?.email}</p>}
                    <input
                      type="tel"
                      name='phone'
                      placeholder='Phone Number'
                      autoComplete='off'
                      value={Data?.phone}
                      onChange={handleChange}
                    />
                    {errors?.phone && <p className="error">{errors?.phone}</p>}
                    <textarea
                      name="message"
                      placeholder='Message'
                      cols="30"
                      rows="6"
                      autoComplete='off'
                      value={Data?.message}
                      onChange={handleChange}
                    ></textarea>
                    {errors?.message && <p className="error" >{errors?.message}</p>}
                    <button type="submit" className='submit_button'>Send Message</button>
                  </form>

                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>

      <section className='socialMedia_section'>
        <div className='social_heading'>
          <h1>Social Media</h1>
          <h2>Follow Our Journey On <p>Social Media</p></h2>
          <div className='contact_socialMedia'>
            <div>
              <div className='social_crd'>
                <img src={InstaIcon} alt="" />
                <p>Instagram</p>
                <Link to="https://instagram.com/8stepstudios?igshid=OGQ5ZDc2ODk2ZA==" target='blank'> Follow us <img src={Vec} alt="" /> </Link>
              </div>
            </div>
            <div>
              <div className='social_crd'>
                <img src={FbIcon} alt="" />
                <p>Facebook</p>
                <Link to="https://www.facebook.com/8stepstudios" target='blank'>Follow us <img src={Vec} alt="" /> </Link>
              </div>
            </div>
            <div>
              <div className='social_crd'>
                <img src={YtIcon} alt="" />
                <p>Youtube</p>
                <Link to="https://youtube.com/@8StepStudios?si=8VvlgzbrI73zsiWq" target='blank'>Follow us <img src={Vec} alt="" /> </Link>
              </div>
            </div>
            <div>
              <div className='social_crd'>
                <img src={PinIcon} alt="" />
                <p>Pinterest</p>
                <Link to='/'>Follow us <img src={Vec} alt="" /> </Link>
              </div>
            </div>
            <div>
              <div className='social_crd'>
                <img src={LinkIcon} alt="" />
                <p>LinkedIn</p>
                {/* <div className='social_para'><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eos, quod?</p></div> */}
                <Link to='/'> Follow us <img src={Vec} alt="" /> </Link>
              </div>
            </div>
          </div>
        </div>
      </section >

      <section className='frequent_section'>
        <Container>
          <div className='faqs_oneon'>
            <h1>FAQ</h1>
            <h4> FAQs</h4>
            <div className='faqs_ques_ans'>
              <Accordion defaultActiveKey="0">
                {faq?.map((val, i) => (
                  <Accordion.Item eventKey={i.toString()} key={i}>
                    <Accordion.Header>{val?.faq_question}</Accordion.Header>
                    <Accordion.Body>{val?.faq_answer}</Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
          </div>
        </Container>
      </section>

      {/* social media instagram */}
      <section className='follow_insta'>
        <div className='follow_content'>
          <h1>Instagram</h1>
          <h4><span>Follow us</span> on Instagram</h4>
        </div>
        <div className='insta_images'>
          <div className='insta_grid1'>
            <img src={Image10} alt="" />
            <img src={Image11} alt="" />
            <img src={Image12} alt="" />
            <img src={Image13} alt="" />
          </div>
          <div className='insta_grid2'>
            <img src={Image14} alt="" />
          </div>
          <div className='insta_grid1'>
            <img src={Image15} alt="" />
            <img src={Image16} alt="" />
            <img src={Image17} alt="" />
            <img src={Image18} alt="" />
          </div>
        </div>
      </section>
      {/*  */}
    </>

  )
}

export default Contact
