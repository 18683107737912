import React from 'react';
import Image10 from '../assets/Images/grid imges/img1.png'
import Image11 from '../assets/Images/grid imges/img2.png'
import Image12 from '../assets/Images/grid imges/img3.png'
import Image13 from '../assets/Images/grid imges/img4.png'
import Image14 from '../assets/Images/grid imges/img5.png'
import Image15 from '../assets/Images/grid imges/img6.png'
import Image16 from '../assets/Images/grid imges/img7.png'
import Image17 from '../assets/Images/grid imges/img8.png'
import Image18 from '../assets/Images/grid imges/img9.png'

function Instagram() {
    return (
        <section className='follow_insta'>
            <div className='follow_content'>
                <h1>Instagram</h1>
                <h4><span>Follow us</span> on Instagram</h4>
            </div>
            <div className='insta_images'>
                <div className='insta_grid1'>
                    <img src={Image10} alt="" />
                    <img src={Image11} alt="" />
                    <img src={Image12} alt="" />
                    <img src={Image13} alt="" />
                </div>
                <div className='insta_grid2'>
                    <img src={Image14} alt="" />
                </div>
                <div className='insta_grid1'>
                    <img src={Image15} alt="" />
                    <img src={Image16} alt="" />
                    <img src={Image17} alt="" />
                    <img src={Image18} alt="" />
                </div>
            </div>
        </section>
    )
}

export default Instagram
