import React from 'react'
import '../../assets/Css/Membership.css'
import Container from 'react-bootstrap/Container'
import Check from '../../assets/Images/tick.png';
import blackTick from '../../assets/Images/blacktick.png';
import Card from 'react-bootstrap/Card'
import { useState, useEffect } from 'react'
import { ApiService } from '../../service/ApiService';
import { Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import SubscribeDrawer from '../../Components/SubscribeDrawer';

function Membership() {
    const navigate = useNavigate()
    const [data, setData] = useState([])
    const [clubmemberData, setClubMemberData] = useState([])
    const GetMembarship = async () => {
        try {
            const response = await ApiService.membarship();
            if (response?.data?.status == 1) {
                setData(response?.data?.data)
            } else {
                setData([])
            }
        } catch (error) {
            console.log(error, 'api erorr')
        }
    }

    const Clubmembarship = async () => {
        try {
            const response = await ApiService.Clubmembarship();
            if (response?.data?.status == '1') {
                setClubMemberData(response?.data?.data)
            } else {
                setClubMemberData([])
            }
        } catch (error) {
            console.log(error, 'api erorr')
        }
    }
    useEffect(() => {
        window.scrollTo(0, 0);
        GetMembarship();
        Clubmembarship()
    }, [])

    const [open, setOpen] = useState(false);

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const ClubHandleClick = ()=>{
        showDrawer()
    }

    return (
        <>
            <section className='memebership_section'>
                <Container fluid>
                    <div className='membership_content'>
                        <h2>Be a Part of Our</h2>
                        <h3>Family</h3>
                        <div className='membership_paragraph'>
                            <p>Your health is your wealth - invest in yourself</p> <p> with our membership plans</p>
                        </div>
                    </div>
                    <div className='memberPlans'>

                        {
                            data?.map((item, i) => {
                                return (
                                    <div className='plan_crd'>
                                        <Card className='pl_card'>
                                            <Card.Body>
                                                <div>
                                                    <span>{item?.plan_name}</span>
                                                    <h3>{item?.plan_amount}</h3>
                                                    <p>{item?.plan_short_desc}</p>
                                                    <hr className='memebership_hr' />

                                                    <div className='memb'>
                                                        <div className='memb_pl'>
                                                            <div className='member_svg'>
                                                                <svg width="19" height="19" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M24 44C29.5228 44 34.5228 41.7614 38.1421 38.1421C41.7614 34.5228 44 29.5228 44 24C44 18.4772 41.7614 13.4772 38.1421 9.85786C34.5228 6.23858 29.5228 4 24 4C18.4772 4 13.4772 6.23858 9.85786 9.85786C6.23858 13.4772 4 18.4772 4 24C4 29.5228 6.23858 34.5228 9.85786 38.1421C13.4772 41.7614 18.4772 44 24 44Z" fill="none" stroke="#333" stroke-width="4" stroke-linejoin="round" /><path d="M16 24L22 30L34 18" stroke="#333" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" /></svg>
                                                            </div>
                                                            <p dangerouslySetInnerHTML={{ __html: item?.plan_desc }}></p>
                                                        </div>
                                                    </div>
                                                    <button className='memb_btn' onClick={showDrawer}>Subscribe !</button>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                )
                            })
                        }
                    </div>

                    <div className='club_membership_wrap'>
                        <div class="grid-container">
                            <div class="grid-item club_cards">
                                {
                                    clubmemberData?.map((item, i) => {
                                      
                                        return (

                                            <div className='club_card_one'>
                                                <p>{item?.club_duration}</p>
                                                <h4>{item?.club_price}</h4>

                                                <div className="memb_text">
                                                    <p dangerouslySetInnerHTML={{ __html: item?.club_long_desc }}></p>

                                                </div>
                                            </div>

                                        )
                                    })
                                }
                            </div>
                            <div class="grid-item club_card_leftside">
                                {/* <div className='memb_pl'>
                                    <img src={Check} alt="" />
                                    <p>
                                        Access to All Activities & Game Zone
                                    </p>
                                </div>
                                <div className='memb_pl'>
                                    <img src={Check} alt="" />
                                    <p>Gym and Cardio Facility</p>
                                </div>
                                <div className='memb_pl'>
                                    <img src={Check} alt="" />
                                    <p>Eight Step Studios Special Program</p>
                                </div>
                                <div className='memb_pl'>
                                    <img src={Check} alt="" />
                                    <p>Access to Swimming Zone</p>
                                </div> */}
                                {
                                    data?.map((item, i) => {
                                        return (
                                            <div className='memb_pl'>
                                                <img src={Check} alt="" />
                                                <p dangerouslySetInnerHTML={{ __html: item?.club_long_desc }}></p>
                                            </div>
                                        )
                                    })
                                }
                                <button className='memb_btn' onClick={ClubHandleClick}>Subscribe !</button>
                            </div>
                        </div>
                    </div>

                    <div className='plansLast_para'>
                        <p>If you want to avail of other amenities like services, then additional charges will be applied, and guess what? You will also unlock the game zone!</p>
                    </div>
                </Container>
            </section>

            <SubscribeDrawer open={open} onClose={onClose} showModal={showDrawer}r />
        </>
    )
}

export default Membership
